import React, { useContext } from "react";

import {
  Tooltip,
  makeUId,
  ColorsProps,
  showTooltip,
  fetchData,
  GradientColors,
  getColorByIndex,
  isGradientColorArr,
  transformLegendsData,
} from "@k2/utils";
import { ThemeContext } from "styled-components";
import {
  VerticalBarSeries,
  XYPlot,
  VerticalBarSeriesPoint,
  CustomSVGSeries,
} from "react-vis";
import cx from "classnames";
import { Legends, useLegends } from "@k2/ui";

import {
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  GradientDefs,
  AxisChartWrapper,
} from "../common";
import { ColumnChartProps } from "./types";
import {
  HeaderStyled,
  chartName,
  ContainerStyled,
  TitleStyled,
} from "./columnChart.style";
import {
  getLabelSeries,
  getIncreasedDomainForLabels,
} from "./columnChart.parser";
import { columnBlankLM, columnLoadingLM } from "./columnChart.blank";

// Callback used in columns to show tooltip on hover.
const handleTooltip = (id: string) => (value: any, event: any) => {
  showTooltip(event.event.target, JSON.stringify(value), id);
};

// Default formatter
const defaultTooltipFormatter = ({
  data,
}: {
  data: VerticalBarSeriesPoint;
}) => {
  return `${data.x}: ${data.y}`;
};

function Chart(props: ColumnChartProps) {
  // Generate tooltip unique id.
  const tooltipId = makeUId();

  const {
    xAxis = true,
    yAxis = true,
    legends = true,
    tooltip = true,
    title,
    style = {},
    barWidth = 0.3,
    classes = {},
    colors = {},
    label = true,
    verticalGridLines = true,
    horizontalGridLines = true,
    xyPlot = {},
    animate = true,
    data: seriesArr = [],
    onClick = () => {},
    className,
  } = props;
  const theme = useContext(ThemeContext);
  // Getting theme properties.
  const {
    mode = "light",
    columnColors: themeColors,
    borderColors: { bar: barBorderColor } = { bar: "#ccc" },
  } = theme;
  // Getting color w.r.t mode.
  const propsColors: ColorsProps = colors[mode];

  // Default Values.
  const { title: xAxisTitle = "", className: xAxisClassName = "" } =
    typeof xAxis === "boolean" ? {} : xAxis;
  const { title: yAxisTitle = "", className: yAxisClassName = "" } =
    typeof yAxis === "boolean" ? {} : yAxis;
  const { yDomain: domain } = xyPlot;

  // Use type gaurd to detect colors are gradient or simple.
  const isGradient = isGradientColorArr(propsColors || themeColors);
  // Get required data for legends from series data.
  const initialLegendsData = transformLegendsData(
    seriesArr,
    propsColors || themeColors,
    isGradient,
  );

  // Data required to make label series.
  // @Note: Label series only works when there are multiple series but
  // with one data item. Stacking need to be true in that case.
  const singleSeriesArr = seriesArr[0];

  // get increased domain to prevent label cutting for single series only
  const yDomainProp =
    label && seriesArr.length === 1
      ? getIncreasedDomainForLabels(singleSeriesArr?.data, domain)
      : domain;

  // Custom hook which handles legends logic.
  const [legendsData, legendsKeyValue, onLegendClick] = useLegends(
    initialLegendsData,
  );
  const verticalGridLinesProps =
    typeof verticalGridLines === "boolean" ? {} : verticalGridLines;
  const horizontalGridLinesProps =
    typeof horizontalGridLines === "boolean" ? {} : horizontalGridLines;
  const showLabelSeries =
    label && legendsKeyValue[singleSeriesArr?.name] && seriesArr.length === 1;
  return (
    <AxisChartWrapper
      chartName={chartName}
      xAxisTitle={xAxisTitle}
      yAxisTitle={yAxisTitle}
      className={cx(className, classes.root)}
      style={style}
    >
      {{
        header: (title || legends) && (
          <HeaderStyled className={classes.header}>
            {title && (
              <TitleStyled className={classes.title}>{title}</TitleStyled>
            )}
            {legends && (
              <Legends
                {...legends}
                data={legendsData}
                onClick={onLegendClick}
                className={cx(
                  classes.legends,
                  typeof legends === "object" && legends.className,
                )}
              />
            )}
          </HeaderStyled>
        ),
        chart: (width, height) => {
          const labelSeries = getLabelSeries(
            singleSeriesArr?.data,
            typeof label !== "boolean" ? label : undefined,
            classes.label || "",
            width || 0,
          );
          return (
            <ContainerStyled>
              <XYPlot
                {...xyPlot}
                width={width || 0}
                height={height || 0}
                className={cx("k2--vertical-bar-chart", classes.chart)}
                xType="ordinal"
                yDomain={yDomainProp}
                animation={animate}
                colorType="literal"
              >
                {isGradient &&
                  GradientDefs({
                    colors:
                      (propsColors as GradientColors) ||
                      (themeColors as GradientColors),
                  })}
                {verticalGridLines && (
                  <VerticalGridLines {...verticalGridLinesProps} />
                )}
                {horizontalGridLines && (
                  <HorizontalGridLines {...horizontalGridLinesProps} />
                )}
                {xAxis && (
                  <XAxis
                    {...xAxis}
                    className={cx(xAxisClassName, classes.xAxis)}
                    // empty title required because title in Chart props xAxis is of type string | JSX.Element
                    // while react vis xAxis props require title of type string
                    title=""
                  />
                )}
                {yAxis && (
                  <YAxis
                    {...yAxis}
                    className={cx(yAxisClassName, classes.yAxis)}
                    // empty title required because title in Chart props xAxis is of type string | JSX.Element
                    // while react vis xAxis props require title of type string
                    title=""
                  />
                )}
                {showLabelSeries && <CustomSVGSeries data={labelSeries} />}
                {seriesArr &&
                  seriesArr.map(
                    (series, index) =>
                      legendsKeyValue[series.name] && (
                        <VerticalBarSeries
                          {...series}
                          stroke={barBorderColor}
                          color={
                            series.color ||
                            getColorByIndex(
                              index,
                              propsColors || themeColors,
                              isGradient,
                            )
                          }
                          key={`column_${series.name}_${index.toString()}`}
                          className={cx(
                            "k2--vertical-bar-series",
                            classes.barSeries,
                          )}
                          onValueClick={(dataPoint: VerticalBarSeriesPoint) => {
                            onClick({ data: dataPoint });
                          }}
                          barWidth={series.barWidth || barWidth}
                          onValueMouseOver={
                            tooltip ? handleTooltip(tooltipId) : undefined
                          }
                        />
                      ),
                  )}
              </XYPlot>
              {tooltip && (
                <Tooltip
                  {...tooltip}
                  id={tooltipId}
                  formatter={
                    typeof tooltip !== "boolean"
                      ? tooltip.formatter || defaultTooltipFormatter
                      : tooltip && defaultTooltipFormatter
                  }
                />
              )}
            </ContainerStyled>
          );
        },
      }}
    </AxisChartWrapper>
  );
}

export const ColumnChart = fetchData({
  blankStateValidator: data => !data || data.length === 0,
  dataValidator: data => {
    return Array.isArray(data)
      ? true
      : Error("Data should be an array of Object");
  },
  propsValidator: (props: any) =>
    props.data ||
    props.url ||
    new Error('Either "data" or "url" prop is required.'),
  defaultComponentStates: {
    loading: columnLoadingLM,
    blank: columnBlankLM,
  },
})<ColumnChartProps>(Chart);

ColumnChart.displayName = "ColumnChart";
export default ColumnChart;
