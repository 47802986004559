import * as React from "react";
import ReactTooltip from "react-tooltip";
import { renderToString } from "react-dom/server";
import { findDOMNode } from "react-dom";
import cx from "classnames";

import { TooltipProps, ClassesProps } from "./types";
import TooltipWrapper from "./tooltip.style";
import { isClient } from "../utils";

const defaultColor = "rgb(32,32,32)";
const defaultTipFormatter = JSON.parse;
const defaultContentMapper = ({ data }: any): string => {
  return JSON.stringify(data) || `${data}`;
};

class Tooltip extends React.Component<TooltipProps> {
  tooltipElement?: Element | Text | null;

  constructor(props: TooltipProps) {
    super(props);
    this.afterHide = this.afterHide.bind(this);
    this.afterShow = this.afterShow.bind(this);
    this.getRef = this.getRef.bind(this);
  }

  getRef(elem: any) {
    if (isClient()) {
      this.tooltipElement = findDOMNode(elem);
    }
  }

  getContentMapper = (
    contentMapper: ({ data }: { data: any }) => any,
    customComponent: boolean = false,
    borderColor?: string | ((data: any) => string),
    classes: ClassesProps = {},
    style: React.CSSProperties = {},
  ) => {
    return (dataTip: string) => {
      if (!dataTip) return;
      const data = defaultTipFormatter(dataTip);
      const resolvedTooltipText =
        !customComponent &&
        // eslint-disable-next-line no-nested-ternary
        (contentMapper
          ? typeof contentMapper === "string"
            ? contentMapper
            : contentMapper({ data }) || "defaultText"
          : "defaultText");
      const isString = typeof resolvedTooltipText === "string";
      const resolvedBorderColor =
        typeof borderColor === "string"
          ? borderColor
          : (borderColor && borderColor(data)) || defaultColor;
      if (isString) return resolvedTooltipText;
      return renderToString(
        <div
          style={{
            paddingBottom: contentMapper ? "none" : "10px",
            ...style,
          }}
        >
          {!customComponent && (
            <div
              className={classes.content}
              style={{
                background: "#fff",
                padding: "4px",
                width: "100%",
                borderColor: resolvedBorderColor,
              }}
            >
              {resolvedTooltipText}
            </div>
          )}
          {customComponent && React.createElement(contentMapper, { data })}
        </div>,
      );
    };
  };

  afterHide() {
    if (this.tooltipElement && isClient()) {
      const tooltipHiddenEvent = new CustomEvent("onTooltipHidden", {
        bubbles: true,
      });

      this.tooltipElement.dispatchEvent(tooltipHiddenEvent);
    }
  }

  afterShow() {
    if (this.tooltipElement && isClient()) {
      const tooltipVisibleEvent = new CustomEvent("onTooltipVisible", {
        bubbles: true,
      });

      this.tooltipElement.dispatchEvent(tooltipVisibleEvent);
    }
  }

  render() {
    const {
      classes = {},
      style = {},
      borderColor = "#000",
      type = "light-default",
      formatter = defaultContentMapper,
      showArrow = true,
      component,
      id,
      className,
    } = this.props;

    const isCustomComponent = !!component;
    const showSimple = type === "light";
    const contentMapper = component || formatter;
    const tooltipType = type !== "light-default" ? type : "light";
    const showBorder = !component && !showSimple;
    let tooltipClasses = ["tooltip"];

    if (isCustomComponent) tooltipClasses.push("tooltipCustomComponent");
    if (!isCustomComponent && type === "light-default")
      tooltipClasses.push("tooltipLight");
    if (!showArrow || isCustomComponent) tooltipClasses.push("tooltipNoArrow");

    return (
      <TooltipWrapper>
        {isClient() && (
          <ReactTooltip
            id={id}
            html
            type={tooltipType}
            border={showBorder}
            className={cx(tooltipClasses, className, classes.root)}
            ref={this.getRef}
            getContent={this.getContentMapper(
              contentMapper,
              !!component,
              borderColor,
              classes,
              style,
            )}
            afterShow={this.afterShow}
            afterHide={this.afterHide}
          />
        )}
      </TooltipWrapper>
    );
  }
}

export default Tooltip;
