import React, { useState, useEffect } from "react";
import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components";
import {
  ThemeKeyValueProps,
  CustomThemeProviderProps,
  ThemeModeProps,
  ThemeStateProps,
  UseEffectToggleThemeProps,
} from "./types";

import { defaultTheme } from "./theme";
import { mergeDeep } from "../utils";

const themes: ThemeKeyValueProps = {
  dark: "dark",
  light: "light",
};

function useToggleThemeEffect(mode = themes.light): UseEffectToggleThemeProps {
  const [themeState, setThemeState] = useState<ThemeStateProps>({
    mode,
    hasThemeMounted: false,
  });

  useEffect(() => {
    const localMode = localStorage.getItem("mode");
    const modeKey =
      localMode === "dark" || localMode === "light" ? localMode : mode;
    setThemeState({
      mode: themes[modeKey],
      hasThemeMounted: true,
    });
  }, [mode]);

  return [themeState, setThemeState];
}

export function ThemeProvider({
  mode = "light",
  children,
  theme = {},
}: CustomThemeProviderProps) {
  const [themeState, setThemeState] = useToggleThemeEffect(mode);
  const toggleMode = (themeName: string) => {
    localStorage.setItem("mode", JSON.stringify(themeName));
    setThemeState(state => ({ ...state, mode: themes[themeName] }));
  };
  const defaultThemeCopy = Object.assign({}, defaultTheme);
  const themeObj: any = mergeDeep(defaultThemeCopy, theme);
  const transformedTheme = {
    ...themeObj,
    ...themeObj[themeState.mode],
    ...themeObj.common,
  };
  // Optional: Remove modes from theme object.
  const keysOfModes: (keyof ThemeModeProps)[] = ["light", "dark", "common"];
  keysOfModes.forEach(key => {
    delete transformedTheme[key];
  });

  return (
    <StyledComponentsThemeProvider
      theme={{
        ...transformedTheme,
        toggleMode,
        mode: themeState.mode,
        themeNames: themes,
      }}
    >
      {children}
    </StyledComponentsThemeProvider>
  );
}
