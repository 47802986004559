import styled from "styled-components";
import { Radio as AntdRadio } from "antd";

const getVairantColor = (props: any) => {
  const variant = props.variant || "primary";
  return props.theme.backgroundColors[variant];
};
export const Radio = styled(AntdRadio)<{ variant: "primary" | "secondary" }>`
  > span {
    &:last-of-type {
      color: ${({ theme }) => theme.textColors.normal};
    }
  }

  &.ant-radio-wrapper {
    .ant-radio {
      .ant-radio-input {
        &:focus {
          & + .ant-radio-inner {
            border-color: ${getVairantColor};
          }
          box-shadow: none;
        }
      }
    }

    &:hover .ant-radio-inner,
    .ant-radio-inner,
    .ant-radio-checked {
      border-color: ${getVairantColor};

      &::after {
        background: ${getVairantColor};
      }
    }
  }
`;

export const RadioGroup = styled(AntdRadio.Group)`
  &.ant-radio-group {
    /* margin-bottom: 20px; */
  }
`;
