import { XOR } from "./types";

type APIData = {
  isLoading: boolean;
  apiData: any;
};

type APIError = {
  isLoading: boolean;
  error: Error;
};

export type FetchResponse = XOR<APIData, APIError>;

/**
 * This function fetches JSON data object
 * from the URL passed to it
 * @export
 * @param {string} url
 * @returns {Promise}
 */
export default async function fetchFromURL(url: string, options?: any): Promise<FetchResponse> {
  try {
    const res = await (options ? fetch(url, options) : fetch(url));
    const result = await res.json();
    return {
      isLoading: true,
      apiData: result,
    };
  } catch (error) {
    return {
      isLoading: false,
      error,
    };
  }
}
