import styled from "styled-components";
import { VerticalGridLines as ReactVisVerticalGridLines } from "react-vis";

const VerticalGridLines = styled(ReactVisVerticalGridLines)<{
  strokeDashWidth?: number;
}>`
  .rv-xy-plot__grid-lines__line {
    stroke: ${({ theme }) => theme.backgroundColors.axis};
    stroke-width: 0.5;
    stroke-dasharray: ${({ strokeDashWidth }) => strokeDashWidth};
  }
`;

export default VerticalGridLines;
