import styled from "styled-components";
import { HorizontalGridLines as ReactVisHorizontalGridLines } from "react-vis";

const HorizontalGridLines = styled(ReactVisHorizontalGridLines)<{
  strokeDashWidth?: number;
}>`
  .rv-xy-plot__grid-lines__line {
    stroke: ${({ theme }) => theme.backgroundColors.axis};
    stroke-width: 0.5;
    stroke-dasharray: ${({ strokeDashWidth }) => strokeDashWidth};
  }
`;

export default HorizontalGridLines;
