import React, { useContext, MouseEvent } from "react";
import cx from "classnames";
import { XYPlot, ArcSeries, CustomSVGSeries } from "react-vis";
import { ThemeContext } from "styled-components";

import {
  GradientColors,
  ColorsProps,
  Tooltip,
  makeUId,
  showTooltip,
  isGradientColorArr,
  fetchData,
  SizeWrapper,
} from "@k2/utils";
import { Legends, useLegends } from "@k2/ui";

import {
  transformData,
  calculateTotal,
  getFilteredData,
  getGradient,
  transformLegendsData,
  getLabels,
} from "./pieChart.parser";
import { PieChartProps, PieChartData, TransformedData } from "./types";
import { GradientDefs } from "../common";
import {
  Wrapper,
  ChartWrapper,
  HeaderStyled,
  TitleStyled,
} from "./pieChart.style";
import { CenterLabel } from "./sub-components/CenterLabel";
import { pieBlankLM, pieLoadingLM } from "./pieChart.blank";

const RADIAL_LABELS_HEIGHT = 40;
const RADIAL_LABELS_WIDTH = 100;
const RADIUS_DIFFERENCE = 10;
const defaultColors = ["#cf7373", "#27b3de", "#e5c238", "#85be50"];

const handleTooltip = (tooltipId: string) => (
  value: TransformedData,
  event: { event: MouseEvent<HTMLElement> },
) =>
  showTooltip(
    event.event.target as HTMLElement,
    JSON.stringify(value),
    tooltipId,
  );
const defaultTooltipFormatter = ({ data }: { data: PieChartData }) =>
  `${data.label}: ${data.value}`;
const RADIAN = Math.PI / 180;

const Chart = (props: PieChartProps) => {
  const {
    title = "",
    width: propsWidth,
    height: PropsHeight,
    data,
    radial = {},
    colors = {},
    label = true,
    style = {},
    centerLabel = true,
    tooltip = true,
    legends = true,
    animate = true,
    classes = {},
    className,
    labelTickWidth = 1.25,
    onClick: onSliceClick,
  } = props;
  // Getting theme properties.
  const { mode = "light", pieColors: themeColors = defaultColors } =
    useContext(ThemeContext) || {};
  // Getting color w.r.t mode.
  const propsColors: ColorsProps = colors[mode];

  // Default Values.
  const { innerRadius, anglePadding = 0 } = radial;
  // Use type gaurd to detect colors are gradient or simple.
  const isGradient = isGradientColorArr(propsColors || themeColors);
  // Adding colors to data
  const dataWithColors = getGradient(
    data,
    propsColors || themeColors,
    isGradient,
  );
  // Adding colors to legends
  const legendsWithColors = transformLegendsData(
    dataWithColors,
    propsColors || themeColors,
    isGradient,
  );
  // Custom hook which handles legends logic.
  const [legendsData, legendsObject, onLegendClick] = useLegends(
    legendsWithColors,
  );
  const CustomLabel = typeof label !== "boolean" ? label : undefined;

  // use showLegends from props if provided else use from theme
  return (
    <Wrapper className={cx(className, classes.root)} style={style}>
      {(legends || title) && (
        <HeaderStyled className={classes.header}>
          {title && (
            <TitleStyled className={classes.title}>{title}</TitleStyled>
          )}
          {legends && (
            <Legends
              {...legends}
              data={legendsData}
              onClick={onLegendClick}
              className={cx(
                classes.legends,
                typeof legends === "object" && legends.className,
              )}
            />
          )}
        </HeaderStyled>
      )}
      <SizeWrapper>
        {{
          chart: (width, height) => {
            const pieWidth = propsWidth || width || 300;
            const pieHeight = PropsHeight || height || 300;
            // Radial Properties
            const size = Math.min(
              pieHeight - (label ? RADIAL_LABELS_HEIGHT : 0),
              pieWidth - (label ? RADIAL_LABELS_WIDTH : 0),
            );
            const outerRadius = size / 2 - RADIUS_DIFFERENCE;
            const clampedInnerRadius = Math.max(
              Math.min(innerRadius || 0, 1),
              0,
            );
            const innerRadiusValue = clampedInnerRadius * outerRadius;

            // Filtered Data
            const visibleData = getFilteredData(dataWithColors, legendsObject);
            // Total sum of values
            const total = calculateTotal(visibleData);
            // Transformed Data
            const transformedData = transformData(
              visibleData,
              total,
              innerRadiusValue,
              outerRadius,
            );

            // Labels Data
            const labelsData =
              label &&
              getLabels(
                visibleData,
                total,
                outerRadius,
                pieWidth,
                pieHeight,
                labelTickWidth,
                classes.label,
                CustomLabel,
              );

            // Generate tooltip unique id.
            const tooltipId = makeUId();

            return (
              <ChartWrapper innerRadius={innerRadiusValue}>
                <XYPlot
                  margin={0}
                  width={pieWidth}
                  height={pieHeight}
                  xDomain={[0, 100]}
                  yDomain={[0, 100]}
                  className={classes.xyplot}
                  animation={animate}
                >
                  {isGradient &&
                    GradientDefs({
                      colors:
                        (propsColors as GradientColors) ||
                        (themeColors as GradientColors),
                    })}
                  <ArcSeries
                    className={classes.arc}
                    animation
                    data={transformedData}
                    padAngle={anglePadding * RADIAN}
                    radiusType="literal"
                    center={{ x: 50, y: 50 }}
                    colorType="literal"
                    stroke="0"
                    onValueMouseOver={handleTooltip(tooltipId)}
                    onValueClick={(value: TransformedData) =>
                      onSliceClick && onSliceClick({ data: value })
                    }
                  />

                  {label && labelsData && <CustomSVGSeries data={labelsData} />}
                </XYPlot>
                {centerLabel && (
                  <CenterLabel
                    data={visibleData}
                    total={total}
                    centerLabel={
                      typeof centerLabel !== "boolean" ? centerLabel : undefined
                    }
                    className={classes.centerlabel}
                  />
                )}
                {tooltip && (
                  <Tooltip
                    {...tooltip}
                    id={tooltipId}
                    className={cx(
                      classes.tooltip,
                      typeof tooltip === "object" && tooltip.className,
                    )}
                    formatter={
                      (typeof tooltip === "object" && tooltip.formatter) ||
                      defaultTooltipFormatter
                    }
                  />
                )}
              </ChartWrapper>
            );
          },
        }}
      </SizeWrapper>
    </Wrapper>
  );
};

export const PieChart = fetchData({
  propsValidator: (props: any) =>
    props.data ||
    props.url ||
    new Error('Either "data" or "url" prop is required.'),
  dataValidator: (data: PieChartData[]) =>
    Array.isArray(data) ? true : Error("Data should be an array of Object"),
  blankStateValidator: (data: PieChartData[]) => !data || data.length === 0,
  defaultComponentStates: {
    loading: pieLoadingLM,
    blank: pieBlankLM,
  },
})<PieChartProps>(Chart);
PieChart.displayName = "PieChart";

export default PieChart;
