// libs
import * as React from "react";
import { withTheme } from "styled-components";
import { LoadingIcon } from "@k2/utils";
// src

import { Layout } from "react-grid-layout";
import * as equals from "react-fast-compare";
import GridLayoutInner from "./GridLayoutInner";
import { generateLayout } from "./generateLayout";
import { GridLayoutProps, GridLayoutPropsWithTheme } from "./types";

type State = {
  layout: null | Layout[];
};

class Grid extends React.Component<GridLayoutProps, State> {
  grid = null;

  constructor(props: GridLayoutProps) {
    super(props);
    this.state = {
      layout: null,
    };
    this.updateGrid = this.updateGrid.bind(this);
  }

  componentDidMount() {
    this.updateGrid();
  }

  componentDidUpdate(prevProps: GridLayoutProps) {
    if (!equals(prevProps, this.props)) this.updateGrid();
  }

  private updateGrid() {
    const {
      children,
      noOfCols: propsNoOfCols,
      rowOffsets: propsRowOffsets,
      rowHeight: propsRowHeight,
      theme: { gridLayout },
    } = this.props as GridLayoutPropsWithTheme;
    const {
      noOfCols: themeNoOfCols,
      rowHeight: themeRowHeight,
      rowOffsets: themeRowOffsets,
    } = gridLayout || {};
    let childrenWithProps;
    if (gridLayout) {
      childrenWithProps = React.Children.map(children, (child, index) => {
        const layoutItem =
          gridLayout.layout && gridLayout.layout[index]
            ? gridLayout.layout[index]
            : {};
        return React.cloneElement(child, {
          heightUnits: child.props.heightUnits || layoutItem.heightUnits,
          widthUnits: child.props.widthUnits || layoutItem.widthUnits,
        });
      });
    }
    const layout = generateLayout(
      childrenWithProps || children,
      propsNoOfCols || themeNoOfCols,
      propsRowOffsets || themeRowOffsets,
      propsRowHeight || themeRowHeight,
    );
    this.setState(() => ({ layout }));
  }

  render() {
    const { theme, gridGap: propsGridGap, rowHeight: propsRowHeight } = this
      .props as GridLayoutPropsWithTheme;
    const {
      gridLayout: {
        gridGap: themeGridGap = undefined,
        rowHeight: themeRowHeight = undefined,
      } = {},
    } = theme;
    const { layout } = this.state;
    if (layout)
      return (
        <GridLayoutInner
          {...this.props}
          rowHeight={propsRowHeight || themeRowHeight}
          gridGap={propsGridGap || themeGridGap}
          layout={layout}
        />
      );
    return <LoadingIcon />;
  }
}

const GridLayout = withTheme<any>(Grid);
GridLayout.displayName = "GridLayout";
export default GridLayout;
