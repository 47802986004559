import React, { useContext, useState, useCallback } from "react";
import { ThemeContext } from "styled-components";
import { XYPlot, AreaSeries, LineSeries, Crosshair } from "react-vis";
import {
  ColorsProps,
  fetchData,
  GradientColors,
  getColorByIndex,
  isGradientColorArr,
  transformLegendsData,
  pluck,
} from "@k2/utils";
import { Legends, useLegends } from "@k2/ui";
import cx from "classnames";

import {
  XAxis,
  YAxis,
  GradientDefs,
  AxisChartWrapper,
  VerticalGridLines,
  HorizontalGridLines,
  PointArr,
  CrosshairComponent,
} from "../common";
import ZoneDefs from "./ZoneDefs";
import { AreaChartProps } from "./types";
import { calculateZones, getCrosshairColor } from "./areaChart.parser";
import { AreaChartStyled, HeaderStyled, TitleStyled } from "./areaChart.style";
import { areaBlankLM, areaLoadingLM } from "./areaChart.blank";
import RvWrapper from "../common/RvWrapper";

function Chart(props: AreaChartProps) {
  const {
    xAxis = true,
    yAxis = true,
    xyPlot = {},
    colors = {},
    verticalGridLines = true,
    horizontalGridLines = true,
    data: seriesArr = [],
    legends = true,
    crosshair = true,
    title,
    zone,
    style = {},
    classes = {},
    onClick,
    animate = true,
  } = props || {};

  const theme = useContext(ThemeContext);

  // Getting theme properties.
  const { mode = "light", areaColors: themeColors } = theme;
  const { margin } = xyPlot;

  const propsColors: ColorsProps = colors[mode];

  const { title: xAxisTitle = "", className: xAxisClassName = "" } =
    typeof xAxis === "boolean" ? {} : xAxis;
  const { title: yAxisTitle = "", className: yAxisClassName = "" } =
    typeof yAxis === "boolean" ? {} : yAxis;
  const {
    component,
    xFormatter,
    yFormatter,
    style: chStyle,
    className: chClassName,
    classes: chClasses = {},
  } = (typeof crosshair === "object" && crosshair) || {};

  const {
    id: zoneId = "",
    axis = "x",
    range = [],
    domain = [0, 0],
    legendComponent,
  } = zone || {};

  const isGradient = isGradientColorArr(propsColors || themeColors);
  // Get required data for legends from series data.
  const initialLegendsData = transformLegendsData(
    seriesArr,
    propsColors || themeColors,
    isGradient,
  );
  const [legendsData, legendsKeyValue, onLegendClick] = useLegends(
    initialLegendsData,
  );
  const [crosshairData, setCrosshairData] = useState<PointArr>([]);
  const dataArr: PointArr[] = pluck(seriesArr, "data") || [];

  /**
   * Event handler for onMouseLeave.
   * @private
   */
  const onMouseLeave = useCallback(() => {
    setCrosshairData([]);
  }, []);

  /**
   * Event handler for onNearestX.
   * @param {Object} value Selected value.
   * @param {index} index Index of the value in the data array.
   * @private
   */
  const onNearestX = useCallback(
    (value, { index }) => {
      // @ts-ignore
      setCrosshairData(dataArr.map(d => d[index]));
    },
    [dataArr],
  );
  const crosshairColor = getCrosshairColor(crosshairData, range, zone);
  const verticalGridLinesProps =
    typeof verticalGridLines === "boolean" ? {} : verticalGridLines;
  const horizontalGridLinesProps =
    typeof horizontalGridLines === "boolean" ? {} : horizontalGridLines;
  return (
    <RvWrapper>
      <AxisChartWrapper
        chartName="area-chart"
        xAxisTitle={xAxisTitle}
        yAxisTitle={yAxisTitle}
        className={classes.root}
        style={style}
      >
        {{
          header: (title || legends) && (
            <HeaderStyled className={classes.header}>
              {title && (
                <TitleStyled className={classes.title}>{title}</TitleStyled>
              )}
              {zone
                ? legendComponent
                : legends && (
                    <Legends
                      {...legends}
                      className={cx(
                        classes.legends,
                        typeof legends === "object" && legends.className,
                      )}
                      data={legendsData}
                      onClick={onLegendClick}
                    />
                  )}
            </HeaderStyled>
          ),
          chart: (width, height) => {
            const { zones, gradients } = calculateZones(
              range,
              axis,
              width || 0,
              height || 0,
              domain,
              margin,
            );
            return (
              <AreaChartStyled className={classes.chart}>
                <XYPlot
                  {...xyPlot}
                  onMouseLeave={onMouseLeave}
                  height={height || 0}
                  width={width || 0}
                  className={classes.xyPlot}
                >
                  {isGradient &&
                    GradientDefs({
                      colors:
                        (propsColors as GradientColors) ||
                        (themeColors as GradientColors),
                    })}
                  {gradients.length > 0 &&
                    GradientDefs({
                      colors: gradients,
                    })}
                  {horizontalGridLines && (
                    <HorizontalGridLines {...horizontalGridLinesProps} />
                  )}
                  {verticalGridLines && (
                    <VerticalGridLines {...verticalGridLinesProps} />
                  )}
                  {seriesArr &&
                    seriesArr.map((series, index) => {
                      const key = `area_${series.name}_${index.toString()}`;
                      const color = zone
                        ? `url(#zone${zoneId})`
                        : series.color ||
                          getColorByIndex(
                            index,
                            propsColors || themeColors,
                            isGradient,
                          );
                      const seriesProps = {
                        ...series,
                        key,
                        color,
                      };

                      return [
                        legendsKeyValue[series.name] && (
                          <AreaSeries
                            {...seriesProps}
                            onNearestX={onNearestX}
                            stroke={undefined}
                            className={cx(
                              seriesProps.className,
                              classes.areaSeries,
                            )}
                            onSeriesClick={() => {
                              onClick && onClick({ data: series });
                            }}
                            animation={animate}
                          />
                        ),
                        legendsKeyValue[series.name] && series.stroke && (
                          <LineSeries
                            curve={series.curve}
                            data={series.data}
                            key={`line${series.name}_${index.toString()}`}
                            color={zone ? `url(#zone${zoneId})` : series.stroke}
                            className={classes.lineSeries}
                            animation={animate}
                          />
                        ),
                      ];
                    })}
                  {crosshair && (
                    <Crosshair
                      {...(typeof crosshair === "object" && crosshair)}
                      className={cx(
                        chClassName,
                        classes.crosshair,
                        chClasses.root,
                      )}
                      values={crosshairData}
                      style={chStyle}
                    >
                      <CrosshairComponent
                        // ts-ignore
                        color={crosshairColor}
                        classes={chClasses}
                        xFormatter={xFormatter}
                        yFormatter={yFormatter}
                        data={seriesArr}
                        pointData={crosshairData}
                        activeSeriesData={legendsKeyValue}
                        component={
                          typeof crosshair !== "boolean" ? component : undefined
                        }
                      />
                    </Crosshair>
                  )}
                  {xAxis && (
                    <XAxis
                      {...xAxis}
                      className={cx(xAxisClassName, classes.xAxis)}
                      // empty title required because title in Chart props xAxis is of type string | JSX.Element
                      // while react vis xAxis props require title of type string
                      title=""
                    />
                  )}
                  {yAxis && (
                    <YAxis
                      {...yAxis}
                      className={cx(yAxisClassName, classes.yAxis)}
                      // empty title required because title in Chart props xAxis is of type string | JSX.Element
                      // while react vis xAxis props require title of type string
                      title=""
                    />
                  )}
                  {zone &&
                    ZoneDefs({
                      zoneId,
                      zones,
                      height: height || 0,
                      width: width || 0,
                    })}
                </XYPlot>
              </AreaChartStyled>
            );
          },
        }}
      </AxisChartWrapper>
    </RvWrapper>
  );
}

export const AreaChart = fetchData({
  propsValidator: (props: any) =>
    props.data ||
    props.url ||
    new Error('Either "data" or "url" prop is required.'),
  blankStateValidator: data => !data || data.length === 0,
  dataValidator: data => {
    return Array.isArray(data)
      ? true
      : Error("Data should be an array of Object");
  },
  defaultComponentStates: {
    blank: areaBlankLM,
    loading: areaLoadingLM,
  },
})<AreaChartProps>(Chart);
AreaChart.displayName = "AreaChart";
export default AreaChart;
