import styled from "styled-components";

const LoadingAnimation = styled.div`
  width: 100%;
  height: 100%;

  > svg {
    opacity: 1;
    animation: animation 1s;
    animation-iteration-count: infinite;
  }

  @keyframes animation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.1;
    }
    100% {
      left: 1;
    }
  }
`;

export default LoadingAnimation;
