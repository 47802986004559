// libs
import * as React from "react";
import styled from "styled-components";

type Props = {
  message?: string;
  error?: boolean;
  refetch?: () => void;
};

const ContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: ${props => props.color};

  svg {
    path {
      fill: ${props => props.color};
    }
  }
`;

const Slate = (props: Props) => {
  const { message = "Data Not Available" } = props;
  const errorColor = "#eb665c";
  return (
    <ContainerStyled color={errorColor}>
      <svg width="42" height="42" viewBox="0 0 47.69 42.17">
        <path d="M46.77 37.13L26.49 2a3.05 3.05 0 00-5.29 0L.92 37.13a3.06 3.06 0 002.65 4.59h40.55a3.07 3.07 0 003.06-3.07 3 3 0 00-.41-1.52zM23.87 12.8a2.31 2.31 0 012.31 2.32v.06l-.38 13.35a1.94 1.94 0 01-3.88 0l-.37-13.35a2.32 2.32 0 012.27-2.38zm0 24.15a2.41 2.41 0 112.4-2.41A2.4 2.4 0 0123.85 37z" />
      </svg>
      <p>{message}</p>
    </ContainerStyled>
  );
};

export default Slate;
