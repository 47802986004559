import React from "react";

import { FlexDivStyled } from "../pieChart.style";
import { PieChartData, LabelComponent } from "../types";

const DefaultLabel = (args: { data: PieChartData }) => {
  const { label, value } = args.data;
  return (
    <div className="k2--pieLabel">
      {label}: <span>{value}</span>
    </div>
  );
};

export const RadialLabel = ({
  startX,
  startY,
  endX,
  endY,
  textX,
  textY,
  midX,
  midY,
  textAnchor,
  className,
  label = DefaultLabel,
  data,
  total,
  textWidth,
}: {
  startX: number;
  startY: number;
  endX: number;
  endY: number;
  midX: number;
  midY: number;
  textX: number;
  textY: number;
  textWidth: number;
  textAnchor: "start" | "end";
  className?: string;
  label?: LabelComponent;
  data: PieChartData & { color: string };
  total: number;
}) => {
  return (
    <g className="k2-pie--radial-label">
      {/* draws a horizontal line for radial label */}
      <path
        d={`M${startX},${startY}L${midX},${midY}L${endX},${endY}`}
        stroke={data.color || "#ccc"}
        fill="none"
      />
      <foreignObject
        x={textX}
        y={textY}
        width={textWidth}
        height="25px"
        className={className}
      >
        <FlexDivStyled textAlign={textAnchor}>
          {React.createElement(label, { data, total })}
        </FlexDivStyled>
      </foreignObject>
    </g>
  );
};
