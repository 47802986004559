import * as React from "react";
import { throttle } from "..";

const styles = {
  width: "100px",
  height: "100px",
  position: "absolute" as "absolute",
  top: "-100000px",
  overflow: "scroll",
  msOverflowStyle: "scrollbar" as "scrollbar",
};
type Props = {
  onLoad?: (scrollbarWidth: number, scrollbarHeight: number) => void;
  onChange?: (scrollbarWidth: number, scrollbarHeight: number) => void;
};
class ScrollbarSize extends React.Component<Props> {
  static defaultProps = {
    onLoad: null,
    onChange: null,
  };

  handleResize = throttle(() => {
    const { onChange } = this.props;
    if (!onChange || this.cancelled) return;
    const prevHeight = this.scrollbarHeight;
    const prevWidth = this.scrollbarWidth;
    this.setMeasurements();
    if (
      prevHeight !== this.scrollbarHeight ||
      prevWidth !== this.scrollbarWidth
    ) {
      onChange(this.scrollbarWidth, this.scrollbarHeight);
    }
  }, 166); // Corresponds to 10 frames at 60 Hz.

  cancelled: boolean = false;

  componentDidMount() {
    const { onLoad, onChange } = this.props;
    this.cancelled = false;
    if (onLoad) {
      this.setMeasurements();
      onLoad(this.scrollbarWidth, this.scrollbarHeight);
    }
    if (onChange) {
      window.onresize = this.handleResize;
    }
  }

  componentWillUnmount() {
    this.cancelled = true;
  }

  setMeasurements = () => {
    const { offsetHeight, offsetWidth, clientWidth, clientHeight } = this.node;
    this.scrollbarHeight = offsetHeight - clientHeight;
    this.scrollbarWidth = offsetWidth - clientWidth;
  };

  scrollbarWidth: number;

  scrollbarHeight: number;

  node: HTMLDivElement;

  render() {
    return (
      <div>
        <div
          style={styles}
          ref={node => {
            if (node) this.node = node;
          }}
        />
      </div>
    );
  }
}

export default ScrollbarSize;
