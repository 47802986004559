import React from "react";
import { SlateWrapper } from "@k2/utils";

const LineSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 54 48"
    width="54"
    height="48"
  >
    <g fill="none" stroke="#c0bfbf" strokeWidth="3">
      <path strokeLinecap="round" strokeLinejoin="round" d="M1.5 1.5v45h51" />
      <path
        data-name="Path 406"
        d="M2.5 35.27h7V19.5h10v9.29h9V6.85h11V35.5h8v-9.15H54"
        strokeMiterlimit="10"
      />
    </g>
  </svg>
);

export const lineLoadingLM: React.ReactElement = (
  <SlateWrapper animate>
    <LineSvg />
  </SlateWrapper>
);
export const lineBlankLM: React.ReactElement = (
  <SlateWrapper>
    <LineSvg />
  </SlateWrapper>
);
