import * as React from "react";
import * as ReactGridLayout from "react-grid-layout";
import { SizeMe } from "react-sizeme";
import { resolveElementProp, isReactComponent } from "@k2/utils";

import { RootStyled } from "./GridLayoutInner.styles";
import { GridLayoutInnerProps } from "./types";
/**
 * This wrapper is used to retrieve width and height passed in the hierarchy from React Grid Layout
 *
 * @param {*} props
 * @returns {React.Component}
 */
type CssPropType = React.ReactText | undefined;

type WrapperProps = {
  child: React.ReactElement<any>;
  style?: {
    width?: CssPropType;
    height?: CssPropType;
  };
  key: string;
};

const onTooltipVisible = (event: CustomEvent) => {
  const { currentTarget } = event;
  if (currentTarget instanceof HTMLElement) {
    currentTarget.style.zIndex = "1";
  }
  event.preventDefault();
};
const onTooltipHidden = (event: CustomEvent) => {
  const { currentTarget } = event;
  if (currentTarget instanceof HTMLElement) {
    currentTarget.style.zIndex = "0";
  }
  event.preventDefault();
};
const getContainerRef = (element: HTMLDivElement | null) => {
  if (element) {
    element.addEventListener("onTooltipVisible", onTooltipVisible);
    element.addEventListener("onTooltipHidden", onTooltipHidden);
  }
};
const Wrapper = (props: WrapperProps) => {
  const {
    child,
    style: { width = undefined, height = undefined } = {},
  } = props;
  const isChildComponent = isReactComponent(child);
  const childProps = isChildComponent
    ? {
        parentWidth: width,
        parentHeight: height,
        width: resolveElementProp(child, "width", width),
        height: resolveElementProp(child, "height", height),
      }
    : {};
  return (
    <div {...props} ref={getContainerRef}>
      {React.cloneElement(child, {
        ...childProps,
      })}
    </div>
  );
};

const ReactGridLayoutWidthProvider = ReactGridLayout.WidthProvider(
  ReactGridLayout,
);

const GridLayoutInner = (props: GridLayoutInnerProps) => {
  const {
    children,
    rowHeight = 8,
    layout,
    isDraggable = true,
    isResizable = true,
    width = "100%",
    isResponsive = true,
    gridGap,
    draggableHandle,
    className,
    style,
  } = props;

  if (React.Children.toArray(children).length === 0) {
    console.info("GridLayout: Add Some Children for Layout to Work.");
    return null;
  }
  const gridChildren = React.Children.toArray(
    children,
  ).map((child: any, i: number) => (
    <Wrapper key={i.toString()} child={child} />
  ));
  const gridProps = {
    cols: 12,
    rowHeight,
    layout,
    isDraggable,
    isResizable,
    margin: gridGap,
    draggableHandle,
    useCSSTransforms: false,
    className,
    style,
  };

  return (
    <RootStyled width={width}>
      {isResponsive && (
        <SizeMe refreshRate={32}>
          {({ size }: any) => {
            const { width: responsiveWidth } = size;
            return (
              <ReactGridLayout {...gridProps} width={responsiveWidth}>
                {gridChildren}
              </ReactGridLayout>
            );
          }}
        </SizeMe>
      )}
      {!isResponsive && (
        <ReactGridLayoutWidthProvider {...gridProps}>
          {gridChildren}
        </ReactGridLayoutWidthProvider>
      )}
    </RootStyled>
  );
};

export default GridLayoutInner;
