import styled from "styled-components";

const TooltipWrapper = styled.div`
  .tooltipCustomComponent {
    background: none !important;
    padding: 0 !important;
  }
  .tooltipNoArrow {
    &:after {
      display: none;
    }
  }
  .tooltip {
    opacity: 1 !important;
    &.place-top::before {
      border-top-color: inherit !important;
    }
    &.place-left::before {
      border-left-color: inherit !important;
    }
    &.place-right::before {
      border-right-color: inherit !important;
    }
    &.place-bottom::before {
      border-bottom-color: inherit !important;
    }
  }
  .tooltipLight {
    border: 1px solid #666 !important;
    padding: 4px !important;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
  }
`;

export default TooltipWrapper;
