import styled from "styled-components";
import { classNamesPrefix } from "@k2/utils";

export const axisChartWrapperClass = classNamesPrefix({
  block: "axis-chart-wrapper",
});

export const axisChartWrapperBodyClass = classNamesPrefix({
  block: "axis-chart-wrapper",
  element: "body",
});

export const xAxisTitleClass = classNamesPrefix({
  block: "axis-chart-wrapper",
  element: "xAxis",
  modifier: "title",
});

export const yAxisTitleClass = classNamesPrefix({
  block: "axis-chart-wrapper",
  element: "yAxis",
  modifier: "title",
});

export const ContainerStyled = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;

  .redgets-axis-chart-wrapper__body {
    display: grid;
    flex: 1;
    min-height: 0;
    grid-template-columns: minmax(0, min-content) minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr) minmax(0, min-content);

    .redgets-axis-chart-wrapper__xAxis--title {
      display: flex;
      position: relative;

      > span {
        position: absolute;
        top: 50%;
        left: 50%;
        white-space: nowrap;
        transform-origin: 0 0;
        transform: rotate(270deg) translate(-50%, -50%);
      }
    }

    > div:nth-of-type(2) {
      width: 100%;
      height: 100%;
    }

    .redgets-axis-chart-wrapper__yAxis--title {
      grid-column: 1/3;
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;

      > span {
        white-space: nowrap;
      }
    }
  }
`;

export const AxisTitleStyled = styled.div<{ width?: string; height?: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  color: ${({ theme }) => theme.textColors.axis};
`;
