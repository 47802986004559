import { pluck } from "@k2/utils";
import { HorizontalBarSeriesPoint, CustomSVGSeriesPoint } from "react-vis";

import React from "react";
import {
  ForeignObjectStyled,
  LabelStyled,
  FlexDivStyled,
} from "./barChart.style";

const DefaultLabel = (args: { data: HorizontalBarSeriesPoint }) => {
  return <LabelStyled>{args.data.x}</LabelStyled>;
};
export function getLabelSeries(
  singleSeriesArr: HorizontalBarSeriesPoint[] = [],
  domain: number[],
  LabelComponent: (args: {
    data: HorizontalBarSeriesPoint;
  }) => JSX.Element = DefaultLabel,
  className: string,
  chartWidth: number,
): CustomSVGSeriesPoint[] {
  const valueArr = pluck(singleSeriesArr, "x");
  const maxValue = Math.max(...valueArr, ...domain);
  const limitPer = (maxValue * 15) / 100;
  const foreignObjectHeight = 25;

  return singleSeriesArr.map((point: HorizontalBarSeriesPoint) => ({
    x: point.x as number,
    y: point.y as number,

    customComponent: (
      row: HorizontalBarSeriesPoint,
      positionInPixels: { x: number; y: number },
    ) => {
      const labelInBar = maxValue - point.x < limitPer;
      return (
        <ForeignObjectStyled
          height={foreignObjectHeight}
          width={`${
            labelInBar ? positionInPixels.x : chartWidth - positionInPixels.x
          }px`}
          className={className}
          translateY={`${-(foreignObjectHeight / 2)}px`}
          translateX={`${labelInBar ? -positionInPixels.x : 0}px`}
        >
          <FlexDivStyled
            justifyContent={labelInBar ? "flex-end" : "flex-start"}
            color={
              (point.labelColor as string) || (point.color as string) || "#ccc"
            }
          >
            <LabelComponent data={point} />
          </FlexDivStyled>
        </ForeignObjectStyled>
      );
    },
  }));
}
