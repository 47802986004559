import React from "react";
import cx from "classnames";
import { CrosshairComponentProps } from "./types";
import { CrosshairStyled, CircleStyled } from "./crosshairTooltip.style";

const DefaultComponent = (
  props: Omit<CrosshairComponentProps, "component">,
) => {
  const {
    data,
    pointData,
    activeSeriesData,
    color,
    xFormatter = value => value.toString(),
    yFormatter = value => value.toString(),
    classes = {
      xValue: "",
      yValue: "",
      seriesLabel: "",
    },
  } = props;

  const xValue = pointData[0].x;
  return (
    <CrosshairStyled>
      <div className={cx("crosshair-header", classes.xValue)}>
        {xFormatter(xValue)}
      </div>
      {data.map(
        (d, index) =>
          activeSeriesData[d.name] && (
            <div
              className="crosshair-item"
              key={`crosshair-tooltip-${index.toString()}`}
            >
              <CircleStyled
                color={color || (d.color as string) || (d.stroke as string)}
              />
              <span className={classes.seriesLabel}>{`${d.name}: `}</span>
              <span className={classes.yValue}>
                {yFormatter(pointData[index].y)}
              </span>
            </div>
          ),
      )}
    </CrosshairStyled>
  );
};

export function CrosshairComponent({
  data,
  pointData,
  activeSeriesData,
  color,
  xFormatter,
  yFormatter,
  classes,
  component = DefaultComponent,
}: CrosshairComponentProps) {
  return React.createElement(component, {
    data,
    pointData,
    activeSeriesData,
    color,
    xFormatter,
    yFormatter,
    classes,
  });
}
