import styled from "styled-components";
import { YAxis as YAxisRV } from "react-vis";

const YAxis = styled(YAxisRV)`
  fill: ${({ theme }) => theme.textColors.axis};

  .rv-xy-plot__axis__line {
    stroke: ${({ theme }) => theme.backgroundColors.axis};
    stroke-width: 0.5;
  }

  .rv-xy-plot__axis__tick__line {
    stroke: ${({ theme }) => theme.backgroundColors.axis};
    stroke-width: 0.5;
  }

  .rv-xy-plot__axis__tick__text {
    fill: ${({ theme }) => theme.textColors.axis};
  }
`;

export default YAxis;
