import React from "react";
import { SlateWrapper } from "@k2/utils";

const AreaSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 54 48"
    width="54"
    height="48"
  >
    <path
      fill="none"
      stroke="#c0bfbf"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
      d="M1.5 1.5v45h51"
    />
    <path
      d="M1.5 32.32s5.5 12.77 8.5-.4 9.5-2.36 9.5-2.36 3 4.71 5-.94l2.78-7.85A4.38 4.38 0 0128.43 19c.86-.79 2.06-1.19 4.07.23 3.33 2.35 4.58-5.06 4.91-7.62a6.32 6.32 0 01.78-2.42C39 7.75 41.5 7 43.5 10.74a23.38 23.38 0 013 9.28 4.12 4.12 0 001.5 2.92c.72.59.52 1 2.52 0 2.53-1.19 5 2.82 5 2.82"
      fill="none"
      stroke="#c0bfbf"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.91"
    />
    <path
      d="M1.5 32.32s5.5 12.77 8.5-.4 9.5-2.36 9.5-2.36 3 4.71 5-.94l2.78-7.85A4.38 4.38 0 0128.43 19c.86-.79 2.06-1.19 4.07.23 3.33 2.35 4.58-5.06 4.91-7.62a6.32 6.32 0 01.78-2.42C39 7.75 41.5 7 43.5 10.74a23.38 23.38 0 013 9.28 4.12 4.12 0 001.5 2.92c.72.59.52 1 2.52 0 2.53-1.19 5 2.82 5 2.82V46.5H1.5z"
      fill="#c0bfbf"
      opacity=".6"
    />
  </svg>
);

export const areaLoadingLM = (
  <SlateWrapper animate>
    <AreaSvg />
  </SlateWrapper>
);
export const areaBlankLM: React.ReactElement = (
  <SlateWrapper>
    <AreaSvg />
  </SlateWrapper>
);
