import React from "react";
import { SlateWrapper } from "@k2/utils";

const PieSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 54 48"
    width="54"
    height="48"
  >
    <path
      d="M27.5 5.83V7h-1V5.83A18.2 18.2 0 008.8 24a18 18 0 001.79 7.82l1.7-.78.42.92-1.71.76a18.21 18.21 0 0016 9.48v-2.7h1v2.65a18.12 18.12 0 0012-5.44l-1.85-1.86.7-.7L40.68 36A18.16 18.16 0 0027.5 5.83z"
      fill="none"
    />
    <path
      d="M11 32.72L5.92 35A23.83 23.83 0 0027 47.8v-5.6a18.21 18.21 0 01-16-9.48zM45.2 24a18.14 18.14 0 01-4.52 12l4 4A23.78 23.78 0 0027.5.21v5.62A18.2 18.2 0 0145.2 24zM8.8 24A18.2 18.2 0 0126.5 5.83V.21A23.83 23.83 0 003.2 24a23.59 23.59 0 002.28 10.14l5.11-2.32A18 18 0 018.8 24zM28 42.15v5.62a23.68 23.68 0 0016-7.1l-4-4a18.12 18.12 0 01-12 5.48z"
      fill="#c0bfbf"
    />
  </svg>
);

export const pieLoadingLM: React.ReactElement = (
  <SlateWrapper animate>
    <PieSvg />
  </SlateWrapper>
);
export const pieBlankLM = (
  <SlateWrapper>
    <PieSvg />
  </SlateWrapper>
);
