import { LabelSeriesPoint } from "react-vis";

import { showTooltip } from "@k2/utils";
import {
  LineMarkSeriesProps,
  TooltipDataProps,
  DataPropsArr,
  DataPoint,
  DataProps,
} from "./types";

/*
  this fucntion takes array of objects and return a single array 
  having maximum "y" value from the arrays
*/
export function getMaxLabelSeriesData(seriesArr: DataPoint[][]) {
  return seriesArr.reduce((acc: DataPoint[], arr: DataPoint[], idx) => {
    if (idx === 0) {
      return arr && arr.map((item: DataPoint) => item);
    }
    const updatedArr = arr.map((item: DataPoint, index: number) => {
      if (item.y > acc[index].y) {
        return item;
      }
      return acc[index];
    });
    return updatedArr;
  }, []);
}

/*
  this fucntion takes array of objects and return a single array 
  having minimum "y" value from the arrays
*/
export function getMinLabelSeriesData(seriesArr: DataPoint[][]) {
  return seriesArr.reduce((acc: DataPoint[], arr: DataPoint[], idx) => {
    if (idx === 0) {
      return arr && arr.map((item: DataPoint) => item);
    }
    const updatedArr = arr.map((item: DataPoint, index: number) => {
      if (item.y < acc[index].y) {
        return item;
      }
      return acc[index];
    });
    return updatedArr;
  }, []);
}

/*
  this function takes an array and return a single maximum value of "y" 
*/
export const getLabelSeriesMaxValue = (data: DataPoint[]): number => {
  return (
    data &&
    data.length &&
    (data.sort((a: DataPoint, b: DataPoint) => {
      const ay = a.y as number;
      const by = b.y as number;
      return by - ay;
    })[0].y as number)
  );
};

/*
  this function takes an array and return a single minimum value of "y" 
*/
export const getLabelSeriesMinValue = (data: DataPoint[]): number => {
  return (
    data &&
    data.length &&
    (data.sort((a: DataPoint, b: DataPoint) => {
      const ay = a.y as number;
      const by = b.y as number;
      return ay - by;
    })[0].y as number)
  );
};

export const handleTooltip = (tooltipId: string) => (
  value: any,
  event: any,
) => {
  showTooltip(event.event.target, JSON.stringify(value), tooltipId);
};

export const defaultTooltipFormatter = (value: TooltipDataProps) => {
  return `${value.x}: ${value.y}`;
};

/*
  this function takes array and return data for the label series in format 
*/
export function getLabelSeriesArr(seriesArr: DataPropsArr) {
  const dataFromSeries = seriesArr.map(series => {
    return series.data as DataPoint[];
  });
  const maxValuesArray = getMaxLabelSeriesData(dataFromSeries);
  const minValuesArray = getMinLabelSeriesData(dataFromSeries);
  const maxValue = getLabelSeriesMaxValue(
    maxValuesArray && [...maxValuesArray],
  );
  const minValue = getLabelSeriesMinValue(
    minValuesArray && [...minValuesArray],
  );

  const labelSeriesValuesDifference = maxValue - minValue;

  const labelSeriesData =
    seriesArr &&
    seriesArr.map(series => {
      const seriesData = series.data as DataPoint[];
      const data: LabelSeriesPoint[] =
        seriesData &&
        seriesData.map((item: DataPoint) => {
          return {
            ...item,
            x: item.x as number,
            y: (item.y as number) + labelSeriesValuesDifference * 0.08,
            label: `${item.y}`,
          };
        });
      return data;
    });

  return labelSeriesData;
}

export function isLineMarkSeries(
  series: DataProps,
): series is LineMarkSeriesProps {
  return (series as LineMarkSeriesProps).size !== undefined;
}
