import React from "react";
import { SlateWrapper } from "@k2/utils";

const BarSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 54 48"
    width="54"
    height="48"
  >
    <g data-name="horizontal chart" fill="none" stroke="#c0bfbf">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M1.5 1.5v45h51"
      />
      <path
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M34 37.75H1.75M48 22.75H1.75M19 7.75H1.75"
      />
    </g>
  </svg>
);

export const barLoadingLM: React.ReactElement = (
  <SlateWrapper animate>
    <BarSvg />
  </SlateWrapper>
);
export const barBlankLM: React.ReactElement = (
  <SlateWrapper>
    <BarSvg />
  </SlateWrapper>
);
