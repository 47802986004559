import React, { useState, useContext, useCallback } from "react";
import { ThemeContext } from "styled-components";
import cx from "classnames";
import {
  LineSeries,
  LineMarkSeries,
  LineSeriesProps,
  Crosshair,
  XYPlot,
} from "react-vis";

import { Legends, useLegends } from "@k2/ui";
import {
  pluck,
  ColorsProps,
  fetchData,
  getColorByIndex,
  isGradientColorArr,
  transformLegendsData,
} from "@k2/utils";

import {
  XAxis,
  YAxis,
  AxisChartWrapper,
  VerticalGridLines,
  HorizontalGridLines,
  PointArr,
  CrosshairComponent,
} from "../common";
import { LineChartProps } from "./types";
import { LineChartStyled, HeaderStyled, TitleStyled } from "./lineChart.style";
import { isLineMarkSeries } from "./lineChart.parser";
import { lineBlankLM, lineLoadingLM } from "./lineChart.blank";
import RvWrapper from "../common/RvWrapper";

function Chart(props: LineChartProps) {
  const {
    xAxis = true,
    yAxis = true,
    xyPlot = {},
    colors = {},
    verticalGridLines = true,
    horizontalGridLines = true,
    data: seriesArr = [],
    legends = true,
    crosshair = true,
    title,
    classes = {},
    style = {},
    onClick,
    animate = true,
  } = props || {};

  const theme = useContext(ThemeContext);
  // Getting theme properties.
  const { mode = "light", lineColors: themeColors } = theme;
  const propsColors: ColorsProps = colors[mode];
  const { title: xAxisTitle = "", className: xAxisClassName = "" } =
    typeof xAxis === "boolean" ? {} : xAxis;
  const { title: yAxisTitle = "", className: yAxisClassName = "" } =
    typeof yAxis === "boolean" ? {} : yAxis;
  const {
    component,
    xFormatter,
    yFormatter,
    style: chStyle,
    className: chClassName,
    classes: chClasses = {},
  } = (typeof crosshair === "object" && crosshair) || {};
  const isGradient = isGradientColorArr(propsColors || themeColors);
  const [legendsData, legendsKeyValue, onLegendCLick] = useLegends(
    transformLegendsData(seriesArr, propsColors || themeColors, isGradient),
  );
  const [crosshairData, setCrosshairData] = useState<PointArr>([]);
  const dataArr: PointArr[] = pluck(seriesArr, "data") || [];
  /**
   * Event handler for onMouseLeave.
   * @private
   */
  const onMouseLeave = useCallback(() => {
    setCrosshairData([]);
  }, []);

  /**
   * Event handler for onNearestX.
   * @param {Object} value Selected value.
   * @param {index} index Index of the value in the data array.
   * @private
   */
  const onNearestX = useCallback(
    (value, { index }) => {
      // @ts-ignore
      setCrosshairData(dataArr.map(d => d[index]));
    },
    [dataArr],
  );

  const verticalGridLinesProps =
    typeof verticalGridLines === "boolean" ? {} : verticalGridLines;
  const horizontalGridLinesProps =
    typeof horizontalGridLines === "boolean" ? {} : horizontalGridLines;
  return (
    <RvWrapper>
      <AxisChartWrapper
        chartName="line-chart"
        xAxisTitle={xAxisTitle}
        yAxisTitle={yAxisTitle}
        className={classes.root}
        style={style}
      >
        {{
          header: (title || legends) && (
            <HeaderStyled className={classes.header}>
              {title && (
                <TitleStyled className={classes.title}>{title}</TitleStyled>
              )}
              {legends && (
                <div className={classes.legends}>
                  <Legends
                    {...legends}
                    data={legendsData}
                    onClick={onLegendCLick}
                  />
                </div>
              )}
            </HeaderStyled>
          ),
          chart: (width, height) => (
            <LineChartStyled>
              <XYPlot
                {...xyPlot}
                onMouseLeave={onMouseLeave}
                width={width || 0}
                height={height || 0}
                className={classes.xyPlot}
              >
                {horizontalGridLines && (
                  <HorizontalGridLines {...horizontalGridLinesProps} />
                )}
                {verticalGridLines && (
                  <VerticalGridLines {...verticalGridLinesProps} />
                )}

                {seriesArr &&
                  seriesArr.map((series, index) => {
                    const key = `line_${series.name}_${index.toString()}`;
                    const color =
                      series.color ||
                      getColorByIndex(
                        index,
                        propsColors || themeColors,
                        isGradient,
                      );

                    const seriesProps = {
                      ...series,
                      key,
                      color,
                      onNearestX,
                    };
                    const Series = isLineMarkSeries(series) ? (
                      <LineMarkSeries
                        {...seriesProps}
                        className={classes.lineMarkSeries}
                        animation={animate}
                        onSeriesClick={() => {
                          onClick && onClick({ data: series });
                        }}
                      />
                    ) : (
                      <LineSeries
                        {...(seriesProps as LineSeriesProps)}
                        className={classes.lineSeries}
                        animation={animate}
                        onSeriesClick={() => {
                          onClick && onClick({ data: series });
                        }}
                      />
                    );
                    return legendsKeyValue[series.name] && Series;
                  })}
                {crosshair && (
                  <Crosshair
                    {...(typeof crosshair === "object" && crosshair)}
                    className={cx(
                      chClassName,
                      classes.crosshair,
                      chClasses.root,
                    )}
                    values={crosshairData}
                    style={chStyle}
                  >
                    <CrosshairComponent
                      classes={chClasses}
                      xFormatter={xFormatter}
                      yFormatter={yFormatter}
                      data={seriesArr}
                      pointData={crosshairData}
                      activeSeriesData={legendsKeyValue}
                      component={
                        typeof crosshair !== "boolean" ? component : undefined
                      }
                    />
                  </Crosshair>
                )}
                {xAxis && (
                  <XAxis
                    {...xAxis}
                    className={cx(xAxisClassName, classes.xAxis)}
                    // empty title required because title in Chart props xAxis is of type string | JSX.Element
                    // while react vis xAxis props require title of type string
                    title=""
                  />
                )}
                {yAxis && (
                  <YAxis
                    {...yAxis}
                    className={cx(yAxisClassName, classes.yAxis)}
                    // empty title required because title in Chart props xAxis is of type string | JSX.Element
                    // while react vis xAxis props require title of type string
                    title=""
                  />
                )}
              </XYPlot>
            </LineChartStyled>
          ),
        }}
      </AxisChartWrapper>
    </RvWrapper>
  );
}

export const LineChart = fetchData({
  propsValidator: (props: any) =>
    props.data ||
    props.url ||
    new Error('Either "data" or "url" prop is required.'),
  blankStateValidator: data => !data || data.length === 0,
  dataValidator: data => {
    return Array.isArray(data)
      ? true
      : Error("Data should be an array of Object");
  },
  defaultComponentStates: {
    blank: lineBlankLM,
    loading: lineLoadingLM,
  },
})<LineChartProps>(Chart);
LineChart.displayName = "LineChart";
export default LineChart;
