import styled from "styled-components";

const LoadingIconWrapper = styled.div`
  .loadingIconWrapper {
    height: "100%";
    width: "100%";
    display: "flex";
    align-items: "center";
    justify-content: "center";
  }
`;
export default LoadingIconWrapper;
