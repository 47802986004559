import React from "react";
import cx from "classnames";

import { PieChartData, CenterLabelComponent } from "../types";

const DefaultCenterLabel = (args: { total: number }) => {
  const label = "Total";
  return (
    <>
      <div className="k2-pie--center-value">{args.total}</div>
      <div className="k2-pie--center-label">{label}</div>
    </>
  );
};

export const CenterLabel = ({
  data,
  total,
  centerLabel = DefaultCenterLabel,
  className,
}: {
  data: PieChartData[];
  total: number;
  centerLabel?: CenterLabelComponent;
  className?: string;
}) => {
  return (
    <div className={cx("k2-pie--center-container", className)}>
      {React.createElement(centerLabel, { data, total })}
    </div>
  );
};
