// libs
import * as React from "react";
import Icon from "antd/lib/icon";

import LoadingIconWrapper from "./style";

const LoadingIcon = () => (
  <LoadingIconWrapper className="loadingIconWrapper">
    <Icon type="loading" style={{ fontSize: 24 }} spin />
  </LoadingIconWrapper>
);

export default LoadingIcon;
