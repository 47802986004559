import styled from "styled-components";

export const LineChartStyled = styled.div`
  height: 100%;
  width: 100%;
  .rv-xy-plot {
    .rv-xy-plot__series {
      &.rv-xy-plot__series--mark {
        circle {
          /* important used below is necessary to override the css  */
          &:hover {
            fill: #ffffff !important;
            stroke-width: 3 !important;
            stroke-dasharray: unset !important;
          }
        }
      }
    }
    .rv-crosshair {
      .rv-crosshair__line {
        background: ${props => props.theme.backgroundColors.crosshairLine};
      }
    }
  }
`;

export const HeaderStyled = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.textColors.heading};
  margin-bottom: 10px;
`;
export const TitleStyled = styled.div`
  font-weight: bold;
`;
