import {
  ThemeParameterProps,
  CommonProps,
  CardProps,
  ThemeInterfaceProps,
} from "./types";

const light: ThemeParameterProps = {
  backgroundColors: {
    crosshairLine: "#C5C5C5",
    primary: "#19CDD7",
    secondary: "blue",
    info: "#e7f7ff",
    error: "#fff2f2",
    success: "#e4f7ec",
    warning: "#fef7e3",
    main: "#ffffff",
    card: "#ffffff",
    tooltip: "#ffffff",
    blankSlate: "#b7b7b7",
    blankSlateInverse: "#eee",
    tableFooter:
      "linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.7) 50%, white 100%)",
    scrollTab: "#c0bfbf",
    axis: "#e6e6e6",
  },
  borderColors: {
    info: "#86d0f1",
    error: "#ff9a9a",
    success: "#70cb98",
    warning: "#e4c66b",
    card: "#e6e6e6",
    bar: "#ffffff",
    normal: "#e6e6e6",
  },
  textColors: {
    info: "#03a9f4",
    error: "#cc4e4e",
    success: "#278f32",
    warning: "#9d7f21",
    normal: "#777",
    heading: "#111",
    axis: "#888888",
    tooltip: "#777",
    card: "#6098f3",
    tableFooter: "rgba(0,0,0,0.5)",
  },
  boxShadowColors: {
    card: "rgba(178, 178, 178, 0.29)",
    tooltip: "0 0 11px 0 rgba(0, 0, 0, 0.09)",
  },
  lineColors: ["#e5be20", "#688bbf", "#27b3de", "#a575b6"],
  areaColors: ["#e5be20", "#688bbf", "#27b3de", "#a575b6"],
  bubbleColors: [
    "#BB856F",
    "#B6B17C",
    "#66cdaa",
    "#42C0FB",
    "#0077ff",
    "#9090FF",
    "#B86DFF",
    "#074700",
    "#FF99FF",
    "#F1536A",
  ],
  sankeyColors: {
    nodeColorScheme: [
      "#BB856F",
      "#B6B17C",
      "#66cdaa",
      "#42C0FB",
      "#0077ff",
      "#9090FF",
      "#B86DFF",
      "#074700",
      "#FF99FF",
      "#F1536A",
    ],
    linkColor: "#828282",
  },
  columnColors: ["#5579ae", "#e5c238", "#CF7373", "#85BE50"],
  barColors: ["#5579ae", "#e5c238", "#CF7373", "#85BE50"],
  pieColors: [
    "#cf7373",
    "#27b3de",
    "#e5c238",
    "#85be50",
    "#ffb964",
    "#ef8057",
    "#6098f3",
  ],
};

const dark: ThemeParameterProps = {
  backgroundColors: {
    crosshairLine: "#394251",
    primary: "#f1227f",
    secondary: "red",
    info: "#81d4fa",
    error: "#ef9a9a",
    success: "#a5d6a7",
    warning: "#ffcc80",
    main: "#000",
    card: "#0c1427",
    tooltip: "#111B33",
    blankSlate: "#1b284b",
    blankSlateInverse: "#eee",
    tableFooter:
      "linear-gradient(to bottom, rgba(12, 20, 39, 0.2) 0%, rgba(12, 20, 39, 0.7) 50%, rgb(12, 20, 39) 100%)",
    scrollTab: "#69727e",
    axis: "#e6e6e6",
  },
  borderColors: {
    info: "#86d0f1",
    error: "#ab4e4e",
    success: "#43815e",
    warning: "#705c21",
    card: "#1b284b",
    bar: "#4C4C4C",
    normal: "#0c1427",
  },
  textColors: {
    info: "#005e8a",
    error: "#6e0808",
    success: "#073b0c",
    warning: "#645114",
    normal: "#9098a6",
    heading: "#f1f1f1",
    axis: "#888888",
    tooltip: "#999",
    card: "#6c96db",
    tableFooter: "rgba(255,255,255,0.5)",
  },
  boxShadowColors: {
    card: "transparent",
    tooltip: "0 0 11px 0 rgba(0, 0, 0, 0.43)",
  },
  lineColors: ["#e5be20", "#688bbf", "#27b3de", "#a575b6"],
  areaColors: ["#e5be20", "#688bbf", "#27b3de", "#a575b6"],
  bubbleColors: [
    "#BB856F",
    "#B6B17C",
    "#66cdaa",
    "#42C0FB",
    "#0077ff",
    "#9090FF",
    "#B86DFF",
    "#074700",
    "#FF99FF",
    "#F1536A",
  ],

  sankeyColors: {
    nodeColorScheme: [
      "#BB856F",
      "#B6B17C",
      "#66cdaa",
      "#42C0FB",
      "#0077ff",
      "#9090FF",
      "#B86DFF",
      "#074700",
      "#FF99FF",
      "#F1536A",
    ],
    linkColor: "white",
  },
  columnColors: ["#5579ae", "#e5c238", "#CF7373", "#85BE50"],
  barColors: ["#5579ae", "#e5c238", "#CF7373", "#85BE50"],
  pieColors: [
    "#cf7373",
    "#27b3de",
    "#e5c238",
    "#85be50",
    "#ffb964",
    "#ef8057",
    "#6098f3",
  ],
};

const common: CommonProps = {
  fontSizes: {
    small: "11px",
    normal: "13px",
    heading: "14px",
    large: "17px",
    xLarge: "21px",
  },
  borderWidth: "0",
  textFontFamily: "roboto, sans-serif",
  numberFontFamily: "Lato, sans-serif",
  letterSpacing: "normal",
};

const card: CardProps = {
  borderRadius: "4px",
  bodyPadding: "20px",
  headerPadding: "10px 20px",
};

export const defaultTheme: ThemeInterfaceProps = {
  dark,
  light,
  common,
  card,
};
