import React from "react";
import { SlateWrapper } from "@k2/utils";

const ColumnSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 54 48"
    width="54"
    height="48"
  >
    <g data-name="bar chart" fill="none" stroke="#c0bfbf">
      <path strokeMiterlimit="10" strokeWidth="6" d="M44 38v9" />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M1.5 1.5v45h51"
      />
      <path
        strokeMiterlimit="10"
        strokeWidth="6"
        d="M12.5 18v28.5M28.25 3v43.5"
      />
    </g>
  </svg>
);

export const columnLoadingLM: React.ReactElement = (
  <SlateWrapper animate>
    <ColumnSvg />
  </SlateWrapper>
);
export const columnBlankLM: React.ReactElement = (
  <SlateWrapper>
    <ColumnSvg />
  </SlateWrapper>
);
