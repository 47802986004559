import { ReactText } from "react";
import styled, { css } from "styled-components";

const getVairantColor = (props: any) => {
  const variant = props.variant || "primary";
  return props.theme.backgroundColors[variant];
};

const borderRadiusStyle = css<{ size?: ReactText }>`
  border-radius: ${props => props.size || "10px"};
`;

export const Button = styled.button<{
  variant: "primary" | "default" | "secondary";
  size?: number;
}>`
  ${borderRadiusStyle};

  background-color: ${getVairantColor};
  padding: 1rem;
`;
