import React, { ReactNode, CSSProperties } from "react";
import { classNamesPrefix } from "@k2/utils";
import { SizeMe } from "react-sizeme";
import cx from "classnames";

import {
  ContainerStyled,
  AxisTitleStyled,
  xAxisTitleClass,
  yAxisTitleClass,
  axisChartWrapperClass,
  axisChartWrapperBodyClass,
} from "./axisChartWrapper.style";

interface AxisChartWrapperProps {
  chartName: string;
  xAxisTitle: string | JSX.Element;
  yAxisTitle: string | JSX.Element;
  className?: string;
  style?: CSSProperties;
  children: {
    header?: ReactNode;
    chart: (width?: number | null, height?: number | null) => JSX.Element;
  };
}

export default function AxisChartWrapper(props: AxisChartWrapperProps) {
  const {
    xAxisTitle,
    yAxisTitle,
    className,
    children: { header = "", chart },
    chartName,
    style,
  } = props;

  const chartClass = classNamesPrefix({
    block: chartName,
  });

  return (
    <ContainerStyled
      className={cx(axisChartWrapperClass, className)}
      style={style}
    >
      {header}
      <div className={axisChartWrapperBodyClass}>
        <AxisTitleStyled
          className={xAxisTitleClass}
          width={xAxisTitle ? "20px" : "initial"}
        >
          <span>{xAxisTitle}</span>
        </AxisTitleStyled>
        <div className={chartClass}>
          <SizeMe monitorHeight>
            {({ size: { width, height } }) => chart(width, height)}
          </SizeMe>
        </div>
        <AxisTitleStyled
          className={yAxisTitleClass}
          height={yAxisTitle ? "20px" : "initial"}
        >
          <span>{yAxisTitle}</span>
        </AxisTitleStyled>
      </div>
    </ContainerStyled>
  );
}
