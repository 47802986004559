import React, { useState } from "react";
import { withSize } from "react-sizeme";

interface SizeWrapperProps {
  children: { chart: (width: number, height: number) => JSX.Element };
}

interface WrapperProps extends SizeWrapperProps {
  height: number;
  width: number;
}

const Wrapper = withSize({ monitorHeight: true })(
  ({ children: { chart }, height, width }: WrapperProps) => {
    return chart(width, height);
  },
);

const SizeWrapper = ({ children }: SizeWrapperProps) => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const onSize = (size: { height: 0; width: 0 }) => {
    setHeight(size.height);
    setWidth(size.width);
  };
  return (
    <Wrapper height={height} width={width} onSize={onSize}>
      {children}
    </Wrapper>
  );
};
export default SizeWrapper;

// another Implementation for SizeWrapper
// const SizeWrapper = ({
//   children: { chart },
// }: SizeWrapperProps): JSX.Element => (
//   <SizeMe monitorHeight>
//     {({ size }) => {
//       const height = size.height || 0;
//       const width = size.width || 0;
//       return chart(width, height);
//     }}
//   </SizeMe>
// );
