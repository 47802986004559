import React from "react";
import { GradientDefs as GradientDefsRV } from "react-vis";
import { GradientColors } from "@k2/utils";

interface GradientDefsProps {
  colors?: GradientColors;
}

export default function GradientDefs(props: GradientDefsProps) {
  const { colors = [] } = props;
  return (
    <GradientDefsRV>
      {colors.map((color, index) => (
        <linearGradient
          key={`gradient_${color}_${index.toString()}`}
          id={color.name.replace(/\s/g, "-")}
          x1={color.x1 || "0"}
          x2={color.x2 || "0"}
          y1={color.y1 || "0"}
          y2={color.y2 || "1"}
        >
          <stop
            offset="0%"
            stopColor={color.startingColor}
            stopOpacity={color.startingColorStopOpacity || 1}
          />
          <stop
            offset="100%"
            stopColor={color.endingColor}
            stopOpacity={color.endingColorStopOpacity || 1}
          />
        </linearGradient>
      ))}
    </GradientDefsRV>
  );
}
