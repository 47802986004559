import styled from "styled-components";

export const chartName = "horizontal-bar-chart";

export const HeaderStyled = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.textColors.heading};
  margin-bottom: 10px;
`;
export const TitleStyled = styled.div`
  font-weight: bold;
`;


export const ForeignObjectStyled = styled.foreignObject<{
  translateY?: string;
  translateX?: string;
}>`
  transform: translate(
    ${props => `${props.translateX || 0},${props.translateY || 0}`}
  );
`;

export const LabelStyled = styled.div<{ color?: string }>`    
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
  }
`;

export const FlexDivStyled = styled.div<{
  justifyContent: string;
  color: string;
}>`
  display: flex;
  padding: 0 5px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: ${props => props.justifyContent};
  color: ${props => props.color};
`;
