import React, { useState, useCallback, useEffect } from "react";

import { Legend, LegendsProps } from "./types";
import { LegendsStyled, SymbolStyled } from "./legends.style";
import cx from 'classnames';
/**
 * Custom hook for managing state for legends.
 * It provides legend data also key value for chart component
 * to disable and enable series.
 * @param legends: Legends data
 */
export function useLegends(
  legends: Legend[],
): [Legend[], { [key: string]: boolean }, (legend: Legend) => void] {
  const dataStr = JSON.stringify(legends);
  const [legendsData, setLegendsData] = useState(legends);
  const onLegendClick = useCallback(
    (legend: Legend) => {
      // Updating data.
      const updatedData = legendsData.map(stateLegend => {
        if (stateLegend.name === legend.name) {
          return {
            ...legend,
            enabled: !legend.enabled,
          };
        }
        return stateLegend;
      });
      setLegendsData(updatedData);
    },
    [legendsData],
  );

  const legendsKeyValue = {};
  // Creates KeyValue from update.
  legendsData.forEach(legend => {
    legendsKeyValue[legend.name] = legend.enabled;
  });

  useEffect(() => {
    setLegendsData(legends);
  }, [dataStr]);

  return [legendsData, legendsKeyValue, onLegendClick];
}

export function Legends(props: Partial<LegendsProps>) {
  const {
    classes = {},
    style = {},
    className,
    onClick,
    data = [],
    symbol = true,
    symbolShape = "circle",
    direction = "row",
    overflow = "auto",
    color = "#ccc",
  } = props;

  const showDefaultSymbol = symbol && typeof symbol === "boolean";
  const showSymbol = !!symbol;
  return (
    <LegendsStyled
      overflow={overflow}
      direction={direction}
      style={style}
      className={cx("k2--legends", className, classes.root)}
    >
      {data.map((item, index) => (
        <div
          key={`legend_${item.name}_${index.toString()}`}
          role="button"
          className={cx("k2--legend-item", classes.item)}
          onClick={() => onClick && onClick(item)}
        >
          {
            showSymbol
              ?
              (item.symbol && typeof item.symbol === "boolean")
                ?
                <SymbolStyled
                  className={cx("k2--legend-symbol", classes.symbol)}
                  enabled={item.enabled}
                  symbolShape={symbolShape}
                  color={item.color || color}
                />
                :
                item.symbol === undefined
                  ?
                  showDefaultSymbol &&
                  <SymbolStyled
                    className={cx("k2--legend-symbol", classes.symbol)}
                    enabled={item.enabled}
                    symbolShape={symbolShape}
                    color={item.color || color}
                  /> || symbol
                  :
                  item.symbol
              :
              null
          }

          <span className={cx("k2--legend-value", classes.value)}>{item.name}</span>
        </div>
      ))}
    </LegendsStyled>
  );
}
