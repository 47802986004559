import { pluck } from "@k2/utils";
import * as React from "react";
import { VerticalBarSeriesPoint, CustomSVGSeriesPoint } from "react-vis";
import {
  ForeignObjectStyled,
  FlexDivStyled,
  LabelStyled,
} from "./columnChart.style";

const DefaultLabel = (args: { data: VerticalBarSeriesPoint }) => {
  return <LabelStyled>{args.data.y}</LabelStyled>;
};
/**
 *  @param seriesData single series array data points .
 *  @param domain domain of chart.
 *  @returns new domain with upper limit increased, or domain param if upper limit lower than max data point
 */
export function getIncreasedDomainForLabels(
  seriesData: VerticalBarSeriesPoint[] = [],
  domain: number[] = [],
): number[] {
  const valueArr = pluck(seriesData, "y");
  const maxDataPoint = Math.max(...valueArr);
  // if domain provided by user has upper limit less than max data value, return domain as it is
  if (maxDataPoint > domain[1] || 0) {
    return domain;
  }
  const newDomain =
    domain.length >= 2 ? [...domain] : [0, domain[1] || maxDataPoint];
  const valueToAddInUpperLimit = maxDataPoint * 0.1;
  if (maxDataPoint > newDomain[1] - valueToAddInUpperLimit) {
    newDomain[1] = maxDataPoint + valueToAddInUpperLimit;
  }
  return newDomain;
}

export function getLabelSeries(
  singleSeriesArr: VerticalBarSeriesPoint[] = [],
  LabelComponent: (args: {
    data: VerticalBarSeriesPoint;
  }) => JSX.Element = DefaultLabel,
  className: string,
  chartWidth: number,
): CustomSVGSeriesPoint[] {
  const foreignObjectHeight = 25;
  const labelWidth = chartWidth / singleSeriesArr.length;

  return singleSeriesArr.map((point: VerticalBarSeriesPoint) => ({
    x: point.x as number,
    y: point.y as number,

    customComponent: () => {
      return (
        <ForeignObjectStyled
          height={foreignObjectHeight}
          width={labelWidth}
          className={className}
          translateY="-25px"
          translateX={`${-labelWidth / 2}px`}
        >
          <FlexDivStyled
            color={
              (point.labelColor as string) || (point.color as string) || "#ccc"
            }
          >
            <LabelComponent data={point} />
          </FlexDivStyled>
        </ForeignObjectStyled>
      );
    },
  }));
}
