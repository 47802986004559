import * as React from "react";

export const generateLayout = (
  children: React.ReactElement<any> | JSX.Element[],
  noOfCols: number = 3,
  rowOffsets: number[] = [],
  rowHeight: number = 14,
) => {
  let grid = initializeGrid(children, noOfCols);
  const gridSize = 12;
  const colWidth = gridSize / noOfCols;
  const layout = React.Children.map(
    children,
    (child: React.ReactElement<any>, i) => {
      const { props: { widthUnits = 1, heightUnits = 1 } = {} } = child;
      const retObj = allocateSpaceInGrid(grid, widthUnits, heightUnits);
      const { x, y, updatedGrid } = retObj;
      grid = updatedGrid;
      return {
        x: x * colWidth,
        y: rowOffsets[y] ? y * rowHeight + rowOffsets[y] : y * rowHeight,
        w: widthUnits * colWidth,
        h: heightUnits,
        i: i.toString(),
      };
    },
  );
  return layout;
};

const initializeGrid = (
  children: React.ReactElement<any> | JSX.Element[],
  noOfCols: number = 3,
): number[][] => {
  // TODO: @Bilal kindly review the code here, i replaced the previous implementation as there was issue with that
  type ChildProps = {
    widthUnits: number;
    heightUnits: number;
  };

  const childrenUnits = React.Children.map(
    children,
    (child: React.ReactElement<ChildProps>) => {
      const {
        props: { widthUnits = 1, heightUnits = 1 },
      } = child;
      return widthUnits * heightUnits;
    },
  );

  const noOfReqdUnits: number = childrenUnits.reduce((totalReqdUnits, unit) => {
    return totalReqdUnits + unit;
  }, 2);

  const noOfRows = Math.ceil(noOfReqdUnits / noOfCols);

  const grid = initZeros([noOfRows, noOfCols]);
  return grid;
};

const allocateSpaceInGrid = (
  passedGrid: number[][],
  reqdWidthUnits: number,
  reqdHeightUnits: number,
): {
  x: number;
  y: number;
  updatedGrid: number[][];
} => {
  const grid = passedGrid.map(arr => arr.slice()); // immuting
  // handling error input
  if (reqdHeightUnits < 1) reqdHeightUnits = 1;
  else reqdHeightUnits = Math.trunc(reqdHeightUnits);

  if (reqdWidthUnits < 1) reqdWidthUnits = 1;
  else reqdWidthUnits = Math.trunc(reqdWidthUnits);

  // case 1: both width and height units are 1 (unit redget)
  if (reqdWidthUnits === 1 && reqdHeightUnits === 1) {
    for (let y = 0; y < grid.length; y += 1) {
      for (let x = 0; x < grid[y].length; x += 1) {
        if (grid[y][x] === 0) {
          grid[y][x] = 1;
          return { updatedGrid: grid, x, y };
        }
      }
    }
  }
  // case 2: widthUnit is greater than one and heightUnit is one (rectangular/horizontal redget)
  // TODO: Assuming reqdWidthUnits is always less than or equal to noOfCols, make it more flexible
  if (reqdWidthUnits > 1 && reqdHeightUnits === 1) {
    for (let y = 0; y < grid.length; y += 1) {
      for (let x = 0; x < grid[y].length; x += 1) {
        if (grid[y][x] === 0) {
          let found = true;
          for (let i = 0; i < reqdWidthUnits; i += 1) {
            if (grid[y][x + i] !== 0) found = false;
          }
          if (found) {
            for (let i = 0; i < reqdWidthUnits; i += 1) {
              grid[y][x + i] = 1;
            }
            return { updatedGrid: grid, x, y };
          }
        }
      }
    }
  }
  // case 3: heightUnit is greater than one and widthUnit is one (rectangular/vertical redget)
  if (reqdWidthUnits === 1 && reqdHeightUnits > 1) {
    for (let y = 0; y < grid.length; y += 1) {
      for (let x = 0; x < grid[y].length; x += 1) {
        if (grid[y][x] === 0) {
          let found = true;
          for (let i = 0; i < reqdHeightUnits; i += 1) {
            if (grid[y + i][x] !== 0) found = false;
          }
          if (found) {
            for (let i = 0; i < reqdHeightUnits; i += 1) {
              grid[y + i][x] = 1;
            }
            return { updatedGrid: grid, x, y };
          }
        }
      }
    }
  }
  // TODO:
  // case 4: both units are greater than 1 (experimental)
  if (reqdWidthUnits > 1 && reqdHeightUnits > 1) {
    for (let y = 0; y < grid.length; y += 1) {
      for (let x = 0; x < grid[y].length; x += 1) {
        if (grid[y][x] === 0) {
          let found = true;
          for (let i = 0; i < reqdWidthUnits; i += 1) {
            for (let j = 0; j < reqdHeightUnits; j += 1) {
              if (grid[y + j][x + i] !== 0) found = false;
            }
          }
          if (found) {
            for (let i = 0; i < reqdWidthUnits; i += 1) {
              for (let j = 0; j < reqdHeightUnits; j += 1) {
                grid[y + j][x + i] = 1;
              }
            }
            return { updatedGrid: grid, x, y };
          }
        }
      }
    }
  }
  // return same grid if no case fulfilled
  return { updatedGrid: grid, x: -1, y: -1 };
};
const initZeros = ([rows, columns]: number[]): number[][] =>
  [...Array(rows)].fill(0).map(z => [...Array(columns)].fill(0));
