import { ReactText } from "react";
import styled from "styled-components";

import { ThemeKeyValueProps } from "@k2/utils";

export interface DividerProps {
  isHorizontal?: boolean;
  margin?: ReactText;
  borderStyle?: string;
  bgColor?: {
    [key in keyof ThemeKeyValueProps]: string;
  };
}

export const Divider = styled.div<DividerProps>`
  width: ${({ isHorizontal = true }) => (isHorizontal ? "100%" : "1px")};
  height: ${({ isHorizontal = true }) => (!isHorizontal ? "100%" : "1px")};
  margin: ${({ margin }) => margin};
  border-left: 1px ${({ borderStyle = "solid" }) => borderStyle}
    ${({ theme = {}, bgColor = {} }) => bgColor[theme.mode || ""] || "#ccc"};
`;
