import {
  ThemeParameterProps,
  CommonProps,
  GridLayoutProps,
  ThemeInterfaceProps,
  RecursivePartial,
} from "./types";

const networkDefault: RecursivePartial<ThemeParameterProps> = {
  backgroundColors: {
    main: "ffffff",
    card: "ffffff",
    primary: "#19CDD7",
    secondary: "#DDB27C",
    info: "#e7f7ff",
    error: "#fff2f2",
    success: "#e4f7ec",
    warning: "#fef7e3",
  },
  borderColors: {
    info: "#86d0f1",
    error: "#ff9a9a",
    success: "#70cb98",
    warning: "#e4c66b",
    card: "#E1E1E1",
  },
  textColors: {
    info: "#03a9f4",
    error: "#cc4e4e",
    success: "#278f32",
    warning: "#9d7f21",
    normal: "#888888",
    heading: "#000000",
  },
  boxShadowColors: {
    card: "rgba(178, 178, 178, 0.29)",
  },
};

const networkDark: RecursivePartial<ThemeParameterProps> = {
  backgroundColors: {
    main: "#33333d",
    card: "#373741",
    primary: "#f1227f",
    secondary: "#DDB27C",
    info: "#81d4fa",
    error: "#ef9a9a",
    success: "#a5d6a7",
    warning: "#ffcc80",
  },
  borderColors: {
    info: "#86d0f1",
    error: "#ab4e4e",
    success: "#43815e",
    warning: "#705c21",
    card: "#4C4C4C",
  },
  textColors: {
    info: "#005e8a",
    error: "#6e0808",
    success: "#073b0c",
    warning: "#645114",
    normal: "#f1f1f1",
    heading: "#ffffff",
  },
  boxShadowColors: {
    card: "transparent",
  },
};

const networkCommon: RecursivePartial<CommonProps> = {
  fontSizes: {
    small: "11px",
    normal: "13px",
    large: "14px",
    xLarge: "18px",
  },
  borderWidth: "0",
  textFontFamily: "roboto, sans-serif",
  numberFontFamily: "Lato, sans-serif",
  letterSpacing: "normal",
};

const networkGridLayout: GridLayoutProps = {
  layout: [
    { widthUnits: 1, heightUnits: 2 },
    { widthUnits: 1, heightUnits: 2 },
    { widthUnits: 1, heightUnits: 2 },
    { widthUnits: 4, heightUnits: 2 },
  ],
  rowHeight: 488,
  noOfCols: 4,
  gridGap: [20, 20],
};

const digitalDefault: RecursivePartial<ThemeParameterProps> = {
  backgroundColors: {
    info: "#e7f7ff",
    error: "#fff2f2",
    success: "#e4f7ec",
    warning: "#fef7e3",
    main: "#f7f7f9",
    card: "#ffffff",
    primary: "#19CDD7",
    secondary: "#DDB27C",
  },
  borderColors: {
    info: "#86d0f1",
    error: "#ff9a9a",
    success: "#70cb98",
    warning: "#e4c66b",
    card: "#E1E1E1",
  },
  textColors: {
    info: "#03a9f4",
    error: "#cc4e4e",
    success: "#278f32",
    warning: "#9d7f21",
    normal: "#56556c",
    heading: "#a7a7bb",
  },
  boxShadowColors: {
    card: "rgba(48, 50, 54, 0.1)",
  },
};

const digitalDark: RecursivePartial<ThemeParameterProps> = {
  backgroundColors: {
    info: "#81d4fa",
    error: "#ef9a9a",
    success: "#a5d6a7",
    warning: "#ffcc80",
    main: "#121212",
    card: "#1c1c1c",
    primary: "#f1227f",
    secondary: "#DDB27C",
  },
  borderColors: {
    info: "#86d0f1",
    error: "#ab4e4e",
    success: "#43815e",
    warning: "#705c21",
    card: "#4C4C4C",
  },
  textColors: {
    info: "#005e8a",
    error: "#6e0808",
    success: "#073b0c",
    warning: "#645114",
    normal: "#E5E5E5",
    heading: "#eee",
  },
  boxShadowColors: {
    card: "rgba(0,0,0,0.3)",
  },
};

const digitalCommon: RecursivePartial<CommonProps> = {
  fontSizes: {
    small: "12px",
    normal: "14px",
    large: "16px",
    xLarge: "18px",
  },
  borderWidth: "1px",
  textFontFamily: "roboto, sans-serif",
  numberFontFamily: "Lato, sans-serif",
  letterSpacing: "normal",
};

export const networkTheme: ThemeInterfaceProps = {
  light: networkDefault,
  dark: networkDark,
  common: networkCommon,
  gridLayout: networkGridLayout,
  card: {
    borderRadius: "4px",
    bodyPadding: "20px",
    headerPadding: "10px 20px",
    hasBorder: false,
    hasBoxShadow: true,
    hasHeaderBorder: false,
  },
};

export const digitalTheme: ThemeInterfaceProps = {
  light: digitalDefault,
  dark: digitalDark,
  common: digitalCommon,
  card: {
    borderRadius: "8px",
    bodyPadding: "20px",
    headerPadding: "10px 20px",
    hasBorder: false,
    hasBoxShadow: true,
    hasHeaderBorder: false,
  },
};
