import { scaleLinear } from "d3-scale";
import { GradientColor } from "@k2/utils";

import { ZoneData, Range, ZoneProps } from "./types";

const DEFAULT_MARGINS = {
  left: 40,
  right: 10,
  top: 10,
  bottom: 40,
};

export function isGradientColor(color: GradientColor | string) {
  return typeof color === "object";
}
export function getGradientURL(color: GradientColor | string) {
  const gradientColor = color as GradientColor;
  return `url(#${gradientColor.name.replace(/\s/g, "-")})`.toString();
}

export const getCrosshairColor = (
  activeCH: { x: number; y: number }[],
  range: Range[],
  zone?: ZoneProps,
): string | undefined => {
  if (!zone || activeCH.length < 1) return undefined;
  const zoneSize = range.length;
  const activeRange = range.filter(z => activeCH[0].x <= z.end);
  const { color } =
    activeRange.length < 1 ? range[zoneSize - 1] : activeRange[0];

  if (isGradientColor(color)) {
    const gradientColor = color as GradientColor;
    return gradientColor.startingColor;
  }
  return color as string;
};

export const calculateZones = (
  range: Range[],
  axis: "x" | "y",
  width: number,
  height: number,
  domain: number[],
  margin?:
    | {
        left?: number;
        top?: number;
        right?: number;
        bottom?: number;
      }
    | number,
) => {
  const marginProps = {
    ...DEFAULT_MARGINS,
    ...(typeof margin === "number"
      ? {
          left: margin,
          right: margin,
          top: margin,
          bottom: margin,
        }
      : margin),
  };
  const { left = 40, top = 10, right = 10, bottom = 40 } = marginProps;
  const xScale = scaleLinear()
    .domain(domain)
    .range([0, width - (left + right)]);
  const yScale = scaleLinear()
    .domain(domain)
    .range([height - (top + bottom), 0]);

  const scale = axis === "x" ? xScale : yScale;
  const gradients = range.reduce(
    (result: GradientColor[], zone: Range): GradientColor[] => {
      const color = zone.color as GradientColor;
      if (isGradientColor(zone.color)) return [...result, color];
      return result;
    },
    [],
  );

  const zones = range.map(
    (zone: Range, index: number): ZoneData => {
      const next = range[index + 1];
      const previous = range[index - 1];
      const color = isGradientColor(zone.color)
        ? getGradientURL(zone.color)
        : zone.color.toString();

      if (!previous) {
        const x = 0;
        const h =
          axis === "y" && height > 0 ? scale(0) - scale(zone.end) : height;
        const y = axis === "y" ? scale(domain[0]) - h : 0;
        const w = axis === "x" && width > 0 ? scale(zone.end) : width;

        return { x, y, w, h, color };
      }
      if (!next) {
        const x = axis === "x" ? scale(previous.end) : 0;
        const y =
          axis === "y" ? scale(previous.end) - height : scale(domain[0]);
        const w = width;
        const h = height;
        return { x, y, w, h, color };
      }
      const h =
        axis === "y" && height > 0
          ? scale(previous.end) - scale(zone.end)
          : height;
      const x = axis === "x" ? scale(previous.end) : 0;
      const y = axis === "y" ? scale(previous.end) - h : 0;
      const w =
        axis === "x" && width > 0
          ? scale(zone.end) - scale(previous.end)
          : width;

      return { x, y, w, h, color };
    },
  );
  return { zones, gradients };
};
