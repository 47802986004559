import styled from "styled-components";

const SlateWrapper = styled.div<{
  animate?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  > svg {
    opacity: 1;
    animation: ${({ animate = false }) => (animate ? `animation 1s` : `none`)};
    animation-iteration-count: infinite;
  }

  @keyframes animation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.1;
    }
    100% {
      left: 1;
    }
  }
`;

export default SlateWrapper;
