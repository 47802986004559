import React, { useContext } from "react";
import {
  Tooltip,
  makeUId,
  ColorsProps,
  showTooltip,
  fetchData,
  GradientColors,
  getColorByIndex,
  isGradientColorArr,
  transformLegendsData,
} from "@k2/utils";
import { ThemeContext } from "styled-components";
import cx from "classnames";
import {
  HorizontalBarSeries,
  FlexibleXYPlot,
  HorizontalBarSeriesPoint,
  CustomSVGSeries,
} from "react-vis";
import { Legends, useLegends } from "@k2/ui";
import {
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  GradientDefs,
  AxisChartWrapper,
} from "../common";

import { BarChartProps } from "./types";
import { HeaderStyled, chartName, TitleStyled } from "./barChart.style";
import { getLabelSeries } from "./BarChart.parser";
import { barBlankLM, barLoadingLM } from "./barChart.blank";

// Callback used in bars to show tooltip on hover.
const handleTooltip = (id: string) => (value: any, event: any) => {
  showTooltip(event.event.target, JSON.stringify(value), id);
};

// Default formatter
const defaultTooltipFormatter = ({
  data,
}: {
  data: HorizontalBarSeriesPoint;
}) => {
  return `${data.y}: ${data.x}`;
};

function Chart(props: BarChartProps) {
  // Generate tooltip  id.
  const tooltipId = makeUId();

  const {
    xAxis = true,
    yAxis = true,
    legends = true,
    barWidth = 0.3,
    title,
    tooltip = true,
    colors = {},
    classes = {},
    style = {},
    label = true,
    verticalGridLines = true,
    horizontalGridLines = true,
    xyPlot = {},
    animate = true,
    data: seriesArr = [],
    onClick = () => {},
    className,
  } = props;
  const theme = useContext(ThemeContext);
  // Getting theme properties.
  const {
    mode = "light",
    barColors: themeColors,
    borderColors: { bar: barBorderColor } = { bar: "#ccc" },
  } = theme;
  // Getting color w.r.t mode.
  const propsColors: ColorsProps = colors[mode];

  // Default Values.
  const { title: xAxisTitle = "", className: xAxisClassName = "" } =
    typeof xAxis === "boolean" ? {} : xAxis;
  const { title: yAxisTitle = "", className: yAxisClassname = "" } =
    typeof yAxis === "boolean" ? {} : yAxis;
  const { xDomain: domain = [] } = xyPlot;
  // Use type gaurd to detect colors are gradient or simple.
  const isGradient = isGradientColorArr(propsColors || themeColors);
  // Get required data for legends from series data.
  const initialLegendsData = transformLegendsData(
    seriesArr,
    propsColors || themeColors,
    isGradient,
  );

  // Data required to make custom svg series series.
  // @Note: Custom svg series to show labels, works in case of sinle series
  const singleSeriesArr = seriesArr[0];
  // Custom hook which handles legends logic.
  const [legendsData, legendsKeyValue, onLegendClick] = useLegends(
    initialLegendsData,
  );
  const verticalGridLinesProps =
    typeof verticalGridLines === "boolean" ? {} : verticalGridLines;
  const horizontalGridLinesProps =
    typeof horizontalGridLines === "boolean" ? {} : horizontalGridLines;
  const showLabelSeries =
    label && legendsKeyValue[singleSeriesArr?.name] && seriesArr.length === 1;
  return (
    <AxisChartWrapper
      chartName={chartName}
      xAxisTitle={xAxisTitle}
      yAxisTitle={yAxisTitle}
      className={cx(className, classes.root)}
      style={style}
    >
      {{
        header: (title || legends) && (
          <HeaderStyled className={classes.header}>
            {title && (
              <TitleStyled className={classes.title}>{title}</TitleStyled>
            )}
            {legends && (
              <Legends
                {...legends}
                className={cx(
                  classes.legends,
                  typeof legends === "object" && legends.className,
                )}
                data={legendsData}
                onClick={onLegendClick}
              />
            )}
          </HeaderStyled>
        ),
        chart: (width, height) => {
          const labelSeries = getLabelSeries(
            singleSeriesArr?.data,
            domain,
            typeof label !== "boolean" ? label : undefined,
            classes.label || "",
            width || 0,
          );
          return (
            <>
              <FlexibleXYPlot
                {...xyPlot}
                width={width || 0}
                height={height || 0}
                className={cx("k2--horizontal-bar-chart", classes.chart)}
                yType="ordinal"
                colorType="literal"
                animation={animate}
              >
                {isGradient &&
                  GradientDefs({
                    colors:
                      (propsColors as GradientColors) ||
                      (themeColors as GradientColors),
                  })}
                {verticalGridLines && (
                  <VerticalGridLines {...verticalGridLinesProps} />
                )}
                {horizontalGridLines && (
                  <HorizontalGridLines {...horizontalGridLinesProps} />
                )}
                {xAxis && (
                  <XAxis
                    {...xAxis}
                    className={cx(xAxisClassName, classes.xAxis)}
                    // AxisChartWrapper renders the axis title, so overriding with ""
                    title=""
                  />
                )}
                {yAxis && (
                  <YAxis
                    {...yAxis}
                    className={cx(yAxisClassname, classes.yAxis)}
                    // AxisChartWrapper renders the axis title, so overriding with ""
                    title=""
                  />
                )}

                {seriesArr &&
                  seriesArr.map(
                    (series, index) =>
                      legendsKeyValue[series.name] && (
                        <HorizontalBarSeries
                          {...series}
                          stroke={barBorderColor}
                          onValueClick={(
                            dataPoint: HorizontalBarSeriesPoint,
                          ) => {
                            onClick({ data: dataPoint });
                          }}
                          color={
                            series.color ||
                            getColorByIndex(
                              index,
                              propsColors || themeColors,
                              isGradient,
                            )
                          }
                          key={`bar${series.name}_${index.toString()}`}
                          className={cx(
                            "k2--horizontal-bar-series",
                            classes.barSeries,
                          )}
                          barWidth={series.barWidth || barWidth}
                          onValueMouseOver={handleTooltip(tooltipId)}
                        />
                      ),
                  )}
                {showLabelSeries && <CustomSVGSeries data={labelSeries} />}
              </FlexibleXYPlot>
              {tooltip && (
                <Tooltip
                  {...tooltip}
                  id={tooltipId}
                  className={cx(
                    classes.tooltip,
                    typeof tooltip === "object" && tooltip.className,
                  )}
                  formatter={
                    (typeof tooltip === "object" && tooltip.formatter) ||
                    defaultTooltipFormatter
                  }
                />
              )}
            </>
          );
        },
      }}
    </AxisChartWrapper>
  );
}

export const BarChart = fetchData({
  blankStateValidator: data => !data || data.length === 0,
  dataValidator: data => {
    return Array.isArray(data)
      ? true
      : Error("Data should be an array of Object");
  },
  propsValidator: (props: any) =>
    props.data ||
    props.url ||
    new Error('Either "data" or "url" prop is required.'),
  defaultComponentStates: {
    loading: barLoadingLM,
    blank: barBlankLM,
  },
})<BarChartProps>(Chart);
BarChart.displayName = "BarChart";
export default BarChart;
