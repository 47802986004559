import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  min-height: 0;
  flex-direction: column;

  .k2--legends-wrapper {
    width: 100%;
  }
`;
export const ChartWrapper = styled.div<{ innerRadius: number }>`
  position: relative;
  flex: 1;
  min-height: 0;

  .k2-pie--center-container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: ${props => -props.innerRadius}px;
    margin-top: ${props => -props.innerRadius}px;
    width: ${props => 2 * props.innerRadius}px;
    height: ${props => 2 * props.innerRadius}px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 50%;
  }

  .k2-pie--center-value {
    font-size: ${props => props.theme.fontSizes.xLarge};
    color: ${({ theme }) => theme.textColors.heading};
    line-height: ${props => props.theme.fontSizes.xLarge};
  }

  .k2-pie--center-label {
    font-size: ${props => props.theme.fontSizes.normal};
    color: ${({ theme }) => theme.textColors.normal};
  }
`;

export const HeaderStyled = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.textColors.heading};
  margin-bottom: 10px;
`;
export const TitleStyled = styled.div`
  font-weight: bold;
`;

export const FlexDivStyled = styled.div<{ textAlign: "start" | "end" }>`
  display: flex;
  cursor: default;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: ${({ textAlign }) =>
    textAlign === "end" ? "flex-end" : "flex-start"};
  flex-direction: column;
  .k2--pieLabel {
    color: ${({ theme }) => theme.textColors.normal};
    font-size: 13px;
    white-space: nowrap;
    > span {
      font-weight: bold;
    }
  }
`;
