import * as React from "react";
import cx from "classnames";
import { RootStyled, HeaderStyled, BodyStyled } from "./Card.style";
import { ActionsComponentProps, TitleProps, CardProps } from "./types";
import { ErrorBoundary } from "../ErrorBoundary";

function Title({
  title: CardTitleElement,
  subtitle: CardSubtitleElement,
  titleClassName,
  subtitleClassName,
}: TitleProps) {
  return (
    <div className={cx("header-title", titleClassName)}>
      {CardTitleElement && (
        <div className="header-title-text">
          {typeof CardTitleElement === "function" ? (
            <CardTitleElement />
          ) : (
            CardTitleElement
          )}
        </div>
      )}
      {CardSubtitleElement && (
        <div className={cx("header-subtitle", subtitleClassName)}>
          {typeof CardSubtitleElement === "function" ? (
            <CardSubtitleElement />
          ) : (
            CardSubtitleElement
          )}
        </div>
      )}
    </div>
  );
}

function Actions({
  actions: ActionsElement,
  className,
}: ActionsComponentProps) {
  return (
    <>
      {ActionsElement && (
        <div className={cx("header-actions", className)}>
          {typeof ActionsElement === "function" ? (
            <ActionsElement />
          ) : (
            ActionsElement
          )}
        </div>
      )}
    </>
  );
}

export const Card = (props: CardProps) => {
  const {
    title,
    children,
    hasBorder,
    hasBoxShadow,
    hasHeaderBorder,
    width = "100%",
    height = "100%",
    classes = {},
    style = {},
    className,
    subtitle = undefined,
    actions = undefined,
  } = props;

  return (
    <ErrorBoundary>
      <RootStyled
        className={cx("k2-card", className, classes.root)}
        width={width}
        height={height}
        hasBorder={hasBorder}
        hasBoxShadow={hasBoxShadow}
        style={style}
      >
        {title && (
          <HeaderStyled
            hasHeaderBorder={hasHeaderBorder}
            className={cx("k2-card-header", classes.header)}
          >
            <Title
              subtitle={subtitle}
              title={title}
              titleClassName={classes.title}
              subtitleClassName={classes.subtitle}
            />
            <Actions actions={actions} className={classes.actions} />
          </HeaderStyled>
        )}
        <BodyStyled className={cx("k2-card-body", classes.body)}>
          {children}
        </BodyStyled>
      </RootStyled>
    </ErrorBoundary>
  );
};
Card.displayName = "Card";
export default Card;
