import styled from "styled-components";

const getAnimation = (ballNumber: number) =>
  `pulse 0.75s ${ballNumber * 0.12}s infinite cubic-bezier(.2,.68,.18,1.08)`;

const PulseLoadingWrapper = styled.div<{
  size: number;
  color: string;
  sizeUnit: string;
}>`
  padding-top: 8px;
  .ball {
    background-color: ${({ color }) => color};
    width: ${({ size, sizeUnit }) => `${size}${sizeUnit}`};
    height: ${({ size, sizeUnit }) => `${size}${sizeUnit}`};
    margin: 2px;
    border-radius: 100%;
    display: inline-block;
    animation-fill-mode: both;
  }
  .ball1 {
    animation: ${getAnimation(1)};
  }

  .ball2 {
    animation: ${getAnimation(2)};
  }

  .ball3 {
    animation: ${getAnimation(2)};
  }

  @keyframes pulse {
    0% {
      transform: "scale(1)";
      opacity: 1;
    }
    45% {
      transform: "scale(0.1)";
      opacity: 0.7;
    }
    80% {
      transform: "scale(1)";
      opacity: 1;
    }
  }
  @keyframes animation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.1;
    }
    100% {
      left: 1;
    }
  }
`;

export default PulseLoadingWrapper;
