import styled from "styled-components";

import { SymbolStyledProps, LegendsStyledProps } from "./types";

export const LegendsStyled = styled.div<LegendsStyledProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  overflow: ${({ overflow }) => overflow};
  color: ${({ theme }) => theme.textColors.normal};
  font-size: ${({ theme }) => theme.fontSizes.normal};

  .k2--legend-item {
    cursor: pointer;
    display: flex;
    margin-left: 13px;
    align-items: center;

    .k2--legend-symbol {
      align-self: center;
    }

    .k2--legend-value {
      white-space: nowrap;
      margin-left: 3px;
    }
  }
`;

export const SymbolStyled = styled.div<SymbolStyledProps>`
  border-radius: ${props =>
    props.symbolShape === "circle" ? "50%" : "initial"};
  width: 10px;
  height: 10px;
  opacity: ${props => (props.enabled ? "1" : "0.1")};
  background: ${props => props.color};
`;
