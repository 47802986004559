import React, { useContext } from "react";
import styled, { ThemeContext, css } from "styled-components";
import { Icon } from "antd";

import { ThemeKeyValueProps } from "@k2/utils";

const upIconAnt = <Icon type="arrow-up" />;
const downIconAnt = <Icon type="arrow-down" />;

export interface TextProps {
  value: React.ReactText;
  fontSize?: React.ReactText;
  fontFamily?: React.ReactText;
  fontWeight?: React.ReactText;
  lineHeight?: React.ReactText;
  textColor?: {
    [key in keyof ThemeKeyValueProps]: string;
  };
}

export interface TextWithThresholdProps
  extends Omit<TextProps, "value" & "textColors"> {
  threshold?: number;
  value: number;
  suffix?: string;
  upIcon?: string | JSX.Element;
  downIcon?: string | JSX.Element;
  isRightAligned?: boolean;
  colors?: {
    [key in keyof ThemeKeyValueProps]: {
      up: string;
      down: string;
    };
  };
}

export interface TextStyledProps extends Omit<TextProps, "value"> {
  isRightAligned?: boolean;
}

const conatinerCss = css<TextStyledProps & { color: string }>`
  font-family: ${({ theme, fontFamily = theme.textFontFamily }) => fontFamily};
  font-size: ${({ theme, fontSize = theme.fontSizes.heading }) => fontSize};
  font-weight: ${({ fontWeight = "bold" }) => fontWeight};
  line-height: ${({ lineHeight = 1.44 }) => lineHeight};
  color: ${({ theme, color = theme.textColors.heading }) => color};
`;

const ContainerTextStyled = styled.div<TextStyledProps>`
  ${conatinerCss}
`;

const ContainerTextWithThresholdStyled = styled.div<TextStyledProps>`
  display: flex;
  flex-direction: ${props => (!props.isRightAligned ? "row-reverse" : "row")};
  ${conatinerCss}
`;

export function TextWithThreshold(props: TextWithThresholdProps) {
  const {
    threshold = 0,
    value,
    upIcon = upIconAnt,
    downIcon = downIconAnt,
    isRightAligned = true,
    suffix = "%",
    colors = {
      light: {
        up: "#1ee298",
        down: "red",
      },
      dark: {
        up: "#1ee298",
        down: "red",
      },
    },
    ...styledProps
  } = props;
  const { mode = "light" } = useContext(ThemeContext) || {};
  const isUp = value >= threshold;
  const icon = isUp ? upIcon : downIcon;
  const color = isUp ? colors[mode].up : colors[mode].down;
  // No sign with zero.
  const sign = isUp && value !== 0 ? "+" : "";

  return (
    <ContainerTextWithThresholdStyled
      {...styledProps}
      color={color}
      isRightAligned={isRightAligned}
    >
      <span>{`${sign}${value}${suffix}`}</span>
      {value !== 0 && <span>{icon}</span>}
    </ContainerTextWithThresholdStyled>
  );
}

export function Text(props: TextProps) {
  const theme = useContext(ThemeContext);
  const { value, textColor = {}, ...styledProps } = props;

  return (
    <ContainerTextStyled
      {...styledProps}
      color={textColor[theme.mode || "light"]}
    >
      {value}
    </ContainerTextStyled>
  );
}
