import styled from "styled-components";

export const AreaChartStyled = styled.div`
  height: 100%;
  .rv-xy-plot {
    .rv-crosshair {
      .rv-crosshair__line {
        background: ${props => props.theme.backgroundColors.crosshairLine};
      }
    }
  }
`;

export const HeaderStyled = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.textColors.heading};
  margin-bottom: 10px;
`;
export const TitleStyled = styled.div`
  font-weight: bold;
`;