import React from "react";
import { GradientDefs as ZoneDefsRV } from "react-vis";
import { ZoneData } from "../types";

interface ZoneDefsProps {
  zoneId: string;
  zones?: ZoneData[];
  height?: number;
  width?: number;
}

export default function ZoneDefs(props: ZoneDefsProps) {
  const { zoneId = "", zones = [], height, width } = props;
  return (
    <ZoneDefsRV>
      <pattern
        id={`zone${zoneId}`}
        key={`zone${zoneId}`}
        x="0"
        y="0"
        width={`${width}`}
        height={`${height}`}
        patternUnits="userSpaceOnUse"
      >
        {zones.map((zone: ZoneData, index: number) => (
          <rect
            id={`zone${index}`}
            key={`zone${zoneId}${index}`}
            x={zone.x}
            y={zone.y}
            width={zone.w}
            height={zone.h}
            fill={zone.color}
            stroke={zone.color}
          />
        ))}
      </pattern>
    </ZoneDefsRV>
  );
}
