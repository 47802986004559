import styled from "styled-components";

export const CrosshairStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 25px;
  box-shadow: 0 0 5px 0 rgba(136, 136, 136, 0.16);
  border: ${props => props.theme.mode === "light" && "solid 1px #f6f6f6"};
  border-radius: 4px;
  font-size: 13px;
  background-color: ${props => props.theme.backgroundColors.tooltip};

  .crosshair-header {
    color: ${props => props.theme.textColors.normal};
    margin-bottom: 10px;
    font-size: 12px;
    white-space: nowrap;
  }

  .crosshair-item {
    display: flex;
    align-items: center;
    line-height: 1.92;
    text-transform: capitalize;

    span {
      white-space: nowrap;
      &:nth-of-type(2),
      &:nth-of-type(3) {
        margin-left: 10px;
      }

      &:nth-of-type(3) {
        font-weight: 600;
      }
    }
  }
`;

export const CircleStyled = styled.span<{ color?: string }>`
  background: ${props => props.color};
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;
